/* LINKS */
// global
$links-letter-spacing: 0.5px;
$links-text-transform: capitalize;
// xs
$links-font-size-xs: 11;
$links-line-height-xs: 16;
// s
$links-font-size-s: 12;
$links-line-height-s: 20;
// m
$links-font-size-m: 14;
$links-line-height-m: 24;
// l
$links-font-size-l: 16;
$links-line-height-l: 24;
