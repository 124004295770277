/** @format */
@use '../../utils/_' as utils;

@mixin margin($first, $second: null, $third: null, $forth: null) {
	@if ($second == null) {
		$_first: utils.ds-getSpace($first);
		margin: $_first;
	} @else if ($third == null) {
		$_first: utils.ds-getSpace($first);
		$_second: utils.ds-getSpace($second);
		margin: $_first $_second;
	} @else if ($forth == null) {
		$_first: utils.ds-getSpace($first);
		$_second: utils.ds-getSpace($second);
		$_third: utils.ds-getSpace($third);
		margin: $_first $_second $_third;
	} @else {
		$_first: utils.ds-getSpace($first);
		$_second: utils.ds-getSpace($second);
		$_third: utils.ds-getSpace($third);
		$_forth: utils.ds-getSpace($forth);
		margin: $_first $_second $_third $_forth;
	}
}

@mixin margin-top($size) {
	margin-top: utils.ds-getSpace($size);
}

@mixin margin-right($size) {
	margin-right: utils.ds-getSpace($size);
}

@mixin margin-bottom($size) {
	margin-bottom: utils.ds-getSpace($size);
}

@mixin margin-left($size) {
	margin-left: utils.ds-getSpace($size);
}

@mixin margin-vertical($size, $size2: null) {
	@if ($size2 == null) {
		@include margin-top($size);
		@include margin-bottom($size);
	} @else {
		@include margin-top($size);
		@include margin-bottom($size2);
	}
}

@mixin margin-horizontal($size, $size2: null) {
	@if ($size2 == null) {
		@include margin-left($size);
		@include margin-right($size);
	} @else {
		@include margin-left($size);
		@include margin-right($size2);
	}
}
