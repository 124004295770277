/** @format */
@import 'styles/vars-sm';

// Cta
$cta-text-color: var(--hmf-gray900);
$cta-font-weight: 700;
$cta-icon-alignment: middle;
$cta-icon-font-size: 1.6rem;
$cta-font-size: 1.4rem;
$cta-margin-left: 0;

// FOCUS RING
$focus-ring-color: ds-getColor('green', 'base', '500', 'dsg');

//carousel
$play-pause-button: ds-getColor('green', 'base', '600', 'dsg');
$circle-slide-indicator-active: ds-getColor('green', 'base', '600', 'dsg');
$chevron-color-carousel: var(--hmf-gray50);
$circle-slide-indicator: var(--hmf-gray100);

//accordion
$accordion-font-family-header: 'Archivo', sans-serif;
$accordion-font-family-text: 'Archivo', sans-serif;
$accordion-flag-color: ds-getColor('orange', 'dark', '500', 'dsg');
$accordion-flag-color-nested: var(--hmf-yellow500);

// Section Header
$section-header-font-color: ds-getColor('green', 'base', '600', 'dsg');
$cta-section-header-text-color: #5e6260;
$cta-arrow-color: ds-getColor('green', 'base', '500', 'dsg');

// Badge variables
$badge-foreground-default: var(--hmf-gray50);
$badge-background-default: ds-getColor('green', 'base', '600', 'dsg');
$badge-font-family: 'DSG Sans Bold', sans-serif;
$badge-foreground-primary: var(--hmf-gray50);
$round-badge-size: 59px;

//BreadCrumb Links
$breadcrumb-link-color: var(--hmf-gray600);
$breadcrumb-link-hover: var(--hmf-gray600);

// FullStory Component
$copybox-light-text-color: var(--hmf-gray50);
$copybox-dark-text-color: var(--hmf-gray900);
$copybox-light-background-color: var(--hmf-gray50);
$copybox-light-background-color-rgb: var(--hmf-gray50-rgb);
$copybox-dark-background-color: var(--hmf-gray900);
$copybox-dark-background-color-rgb: var(--hmf-gray900-rgb);

//HalfHero Component
$half-hero-translucent-background-color: rgba(0, 15, 8, 0.35);

//Page Nav Component
$color-gg-standard-blue: #00203a;

// Certona
$certona-header-color: var(--hmf-gray900);
$certona-heading-transform: uppercase;
$heading-font-size: $font-size-md-3;

// Creative Certona
$creative-certona-price-color: ds-getColor('green', 'base', '600', 'dsg');

//Category
$category-background-initial: $color-dsg-gray-light-2;
$category-background-hover: $color-dsg-gray-light-3;
$category-scroll-color: var(--hmf-gray900);

$heading-font-color: var(--hmf-gray900);
$border-top-hr: 1px solid var(--hmf-gray200);

$app-home-background-color: var(--hmf-gray50);
$app-chain-background-color: var(--hmf-gray50);

// Release Calendar
$release-calendar-accent-color: ds-getColor('orange', 'dark', '500', 'dsg');

// Headers
$header-background-color-dark: ds-getColor('green', 'base', '600', 'dsg');
$header-background-color-light: var(--hmf-gray50);

// Steps
$steps-background-round-dark: ds-getColor('green', 'base', '600', 'dsg');
$steps-background-round-light: var(--hmf-gray50);

//Tabbing Component
$tabbing-list-background-color: rgba(ds-getColor('green', 'base', '600', 'dsg'), 0.8);
$tabbing-label-active-color: ds-getColor('orange', 'dark', '500', 'dsg');
$tabbing-header-pagination-color: ds-getColor('green', 'base', '600', 'dsg');
$tabbing-list-text-color: var(--hmf-gray50);
$tabbing-banner-text-color: ds-getColor('green', 'base', '800', 'dsg');

@import './brand-variables/text/dsg';
