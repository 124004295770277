/** @format */
@use 'sass:map';
@use '../../utils/_' as utils;

$tokens-spacing: (
	0: utils.ds-remCalc(0px),
	xxxs: utils.ds-remCalc(4px),
	xxs: utils.ds-remCalc(8px),
	xs: utils.ds-remCalc(12px),
	s: utils.ds-remCalc(16px),
	m: utils.ds-remCalc(24px),
	l: utils.ds-remCalc(32px),
	xl: utils.ds-remCalc(48px),
	xxl: utils.ds-remCalc(72px),
	xxxl: utils.ds-remCalc(96px),
	xxxxl: utils.ds-remCalc(120px)
);

@function ds-getSpace($size) {
	@return map.get($tokens-spacing, $size);
}
