/**
 * @author Craig McMurray
 *
 * @description
 * This file should only contain CSS that is common across the entire application.  Chain specific CSS should not
 * be contained in this file.  This file follows the SMACSS design philosophy (https://smacss.com)
 *
 * NOTE: This file will be extracted to main dist folder and is imported in index.html
 */
/*************** IMPORT STYLES ***************/
// Chain Specific Variables
@import 'chain-vars';

// Import the common styles CSS
@import 'global';

.button-primary {
	@include btn-monorepo;
	@include heading-sm;
	color: var(--hmf-gray50);
	background-color: $button-primary-background;
	border-radius: 2px;
	border: 1px solid $button-primary-background;
	&:hover {
		background-color: $button-primary-background-hover;
		border: 1px solid $button-primary-background-hover;
	}
	&:focus {
		outline: 1px dashed $attribute-focus-dashed;
		outline-offset: var(--hmf-xxxs);
	}
}
