/**
 * Native Element Resets
 *
 * @format
 */

@mixin reset-button() {
	font-style: inherit;
	font-size: inherit;
	text-decoration: none;
	background: none;
	margin: 0;
	border: none;
}
