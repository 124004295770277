/** @format */
@use 'sass:map';
@use '../../tokens/grid/grid' as grid;
@use '../breakpoints/' as breakpoints;
@use '../../utils/_' as utils;

$_gutterWidth: map.get(grid.$tokens-grid, 'gutter-width');
$_columnWidth: map.get(grid.$tokens-grid, 'column-width');
$_columnCount: map.get(grid.$tokens-grid, 'column-count');

.homefield-col {
	box-sizing: border-box;
	@each $breakPointSizeKey, $value in utils.$homefield-breakpoints {
		@include breakpoints.media-breakpoint-up($breakPointSizeKey) {
			padding-left: map.get($_gutterWidth, $breakPointSizeKey) / 2;
			padding-right: map.get($_gutterWidth, $breakPointSizeKey) / 2;
		}
	}
}

.homefield-grid {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	// @each $breakPointSizeKey, $value in breakpoints.$tokens-breakpoints {
	// 	@include style-grid.ds-respond-to($breakPointSizeKey) {
	// 		margin-left: -(map.get($_gutterWidth, $breakPointSizeKey) / 2);
	// 		margin-right: -(map.get($_gutterWidth, $breakPointSizeKey) / 2);
	// 	}
	// }
}

.homefield-grid > hr {
	border: 0 !important; // TODO: !important should not be used
	height: 0 !important; // TODO: !important should not be used
	width: 100% !important; // TODO: !important should not be used
	font-size: 0 !important; // TODO: !important should not be used
	content: '' !important; // TODO: !important should not be used
}

@each $breakPointSizeKey, $value in utils.$homefield-breakpoints {
	@for $i from 0 through $_columnCount {
		.homefield-span-#{$i} {
			width: $_columnWidth * $i;
		}
		.homefield-offset-#{$i} {
			margin-left: $_columnWidth * $i;
			margin-right: $_columnWidth * $i;
		}
		@include breakpoints.media-breakpoint-up($breakPointSizeKey) {
			.homefield-span-#{$breakPointSizeKey}-#{$i} {
				width: $_columnWidth * $i !important;
			} // TODO: !important should not be used
			.homefield-offset-#{$breakPointSizeKey}-#{$i} {
				margin-left: $_columnWidth * $i !important;
				margin-right: $_columnWidth * $i !important;
			} // TODO: !important should not be used
		}
	}
}

// Container classes
@each $containerKey, $containerMap in grid.$tokens-gridContainers {
	.#{$containerKey} {
		@each $breakPointSizeKey, $margin in map.get($containerMap, margins) {
			@if ($breakPointSizeKey == 'xs') {
				padding-left: $margin;
				padding-right: $margin;
			} @else {
				@include breakpoints.media-breakpoint-up($breakPointSizeKey) {
					padding-left: $margin;
					padding-right: $margin;
				}
			}
		}
	}
}
