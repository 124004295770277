/* BODY */
// global
$body-font-letter-spacing: 0.5px;
// s
$body-font-size-s: 12;
$body-line-height-s: 20;
// m
$body-font-size-m: 14;
$body-line-height-m: 24;
// l
$body-font-size-l: 16;
$body-line-height-l: 24;
