/**
 * @author Craig McMurray
 *
 * @description
 * This file should only contain CSS that is common across the entire application.  Chain specific CSS should not
 * be contained in this file.  This file follows the SMACSS design philosophy (https://smacss.com)
 *
 * NOTE: This file will be extracted to main dist folder and is imported in index.html
 */
/*************** IMPORT STYLES ***************/
//Chain Specific Variables
@use '@angular/material' as mat;

@import "bootstrap";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

@import 'homefield';

// Do the following to provide missing styles for material components (legacy or new)
// Define theme
$indigo-pink-theme: mat.define-light-theme((
 color: (
   primary: mat.define-palette(mat.$indigo-palette),
   accent: mat.define-palette(mat.$pink-palette),
 ),
));

// Pass light theme to legacy select component
@include mat.legacy-select-color($indigo-pink-theme);

/*************** BASE STYLES ***************/
*,
*:after,
*::before {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
// NO!
// *:focus {
// 	outline: none !important;
// }

html {
	font-size: 62.5%; //Needed to use REM as pixels as base 10.  For example, 14px == 1.4rem
}

html,
body {
	height: 100%; //Needed so we can set height to 100% of Window
}

// body,
// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
// 	font-family: $font-family-primary;
// }

h1 {
	font-size: 2rem;
}

body {
	font-size: $font-size-base;
	color: $primary-font-color;
	background-color: var(--hmf-gray100);
	&.clp {
		background-color: var(--hmf-gray50);
		font-family: $font-family-primary, Sans-Serif;
	}
}
/*************** LAYOUT STYLES ***************/
.content-container {
	//margin: 0 auto 50px;
	//max-width: 960px;

	&.responsive {
		@media screen and (min-width: 1281px) {
			max-width: 1660px !important;
		}
	}
}

.app-container {
	max-width: 1600px !important;
	overflow: hidden;
}

.layout-container {
	position: relative;
	height: 100%;
	width: 100%;
	overflow: hidden;
	background-color: var(--hmf-gray50);

	.layout-canvas {
		position: relative;
		width: 100%;
		height: 100%;
		backface-visibility: hidden;

		.layout-content {
			position: relative;
			height: 100%;
			overflow-y: auto;
			overflow-x: hidden;
			//-webkit-overflow-scrolling: touch; //Momentum scrolling in iOS

			&::-webkit-scrollbar {
				display: none;
			}
		}
	}
}

/*
 * CUSTOM ELEMENTS
 */
cart-mini-cart,
common-accordion,
common-alert-message,
common-progress-spinner,
common-side-nav,
product-store-layer {
	display: block;
}

.espot-bottom-margin-standard {
	margin-bottom: 2rem;
}

.no-border {
	border: none !important;
}

.no-padding {
	padding: 0 !important;
}

.no-padding-bottom {
	padding-bottom: 0 !important;
}

.no-padding-top {
	padding-top: 0 !important;
}

.no-margin-bottom {
	margin-bottom: 0 !important;
}

.no-margin-top {
	margin-top: 0 !important;
}

/*************** MODULE STYLES ***************/
/*
 * SVGs
 */
.dcsg-svg-icon {
	height: 16px;
	width: 16px;

	&.error-icon {
		filter: $svg-filter-error-icon-color;
	}

	&.info-icon {
		filter: $svg-filter-info-icon-color;
	}

	&.warn-icon {
		filter: $svg-filter-warn-icon-color;
	}

	&.success-icon {
		filter: $svg-filter-success-icon-color;
	}
}

/*
 * CARDS
 */
.dcsg-card {
	&:hover {
		box-shadow: 0 2px 8px ds-getColor($dcsg-card-box-shadow-color-color, $dcsg-card-box-shadow-color-level, $dcsg-card-box-shadow-color-value, $theme-color-chain);
	}

	&:active,
	&:focus {
		outline: var(--hmf-gray600);
	}

	&__border {
		border: 1px solid var(--hmf-gray400);
		&-radius {
			border-radius: 8px;
		}
	}
}

//BUTTONS
.btn {
	// font-family: $font-family-buttons;
	font-family: $primary-font-family;

	&:focus {
		box-shadow: none;
	}

	&.dcsg-button {
		border-radius: 0 !important;
		//text-transform: uppercase !important;
	}

	&.dcsg-button-primary {
		height: 4.4rem;
		border: $btn-border-primary;
		background-color: $btn-background-primary;
		color: $btn-font-color-primary;
		font-size: 1.8rem;
		font-weight: 400;
		text-transform: uppercase;
	}

	&.dcsg-button-secondary {
		height: 4.4rem;
		border: $btn-border-secondary;
		background-color: $btn-background-secondary;
		color: $btn-font-color-secondary;
		font-size: 1.8rem;
		font-weight: 400;
		text-transform: capitalize;
	}

	&.dcsg-button-gradient-primary {
		background-image: -webkit-gradient(linear, right top, left bottom, from($btn-gradient-top-primary), to($btn-gradient-bottom-primary));
		background-image: -webkit-linear-gradient(top, $btn-gradient-top-primary, $btn-gradient-bottom-primary);
		background-image: -moz-linear-gradient(top, $btn-gradient-top-primary, $btn-gradient-bottom-primary);
		background-image: -ms-linear-gradient(top, $btn-gradient-top-primary, $btn-gradient-bottom-primary);
		background-image: -o-linear-gradient(top, $btn-gradient-top-primary, $btn-gradient-bottom-primary);
		background-image: linear-gradient(to top, $btn-gradient-top-primary, $btn-gradient-bottom-primary);
	}

	&.dcsg-button-gradient-secondary {
		background-image: -webkit-gradient(linear, right top, left bottom, from($btn-gradient-top-secondary), to($btn-gradient-bottom-secondary));
		background-image: -webkit-linear-gradient(top, $btn-gradient-top-secondary, $btn-gradient-bottom-secondary);
		background-image: -moz-linear-gradient(top, $btn-gradient-top-secondary, $btn-gradient-bottom-secondary);
		background-image: -ms-linear-gradient(top, $btn-gradient-top-secondary, $btn-gradient-bottom-secondary);
		background-image: -o-linear-gradient(top, $btn-gradient-top-secondary, $btn-gradient-bottom-secondary);
		background-image: linear-gradient(to top, $btn-gradient-top-secondary, $btn-gradient-bottom-secondary);
	}

	&.dcsg-button-large {
		font-size: 2.2rem;
		font-weight: $btn-font-weight-large;
		text-transform: uppercase;
	}

	/*
	&:focus,
	&:active {
		box-shadow: none;
		background-color: $btn-background-primary-active;
		border: $btn-border-primary-active;
	}

	&:hover {
		background-color: ds-getColor("gray", "base", "300");
		border: ds-getColor("gray", "base", "300");
	}
	*/
}

/*
 * HYPERLINKS
 */
a {
	color: inherit;
	cursor: pointer;

	&:hover {
		color: inherit;
		text-decoration: none;
	}

	&:active {
		color: inherit;
		text-decoration: none;
	}

	&:visited {
		color: inherit;
		text-decoration: none;
	}
}

.hyperlink {
	cursor: pointer;
	color: $hyperlink-color-primary;

	&:hover {
		color: $hyperlink-color-primary-hover;
	}

	&:active {
		color: $hyperlink-color-primary-active;
	}

	&:visited {
		color: $hyperlink-color-primary-visited;
	}
}

/*
 * NG-BOOTSTRAP START
 */
.modal {
	.modal-header {
		background-color: $modal-header-background;
		border-bottom: 0.1rem solid $modal-header-border-bottom;
		border-top-left-radius: 0;
		border-top-right-radius: 0;

		.close {
			font-size: 2em;
		}
	}

	.modal-dialog {
		// Phone only
		@media screen and (max-width: 767px) {
			margin: 0;
			max-width: none;
		}
	}

	.modal-content {
		border: none;
		border-radius: 0;
	}

	.modal-footer {
		border-top: 0;

		.dcsg-button-set {
			&.row {
				width: 100%;

				> div:nth-child(n + 2) {
					// Phone only
					@media screen and (max-width: 767px) {
						margin-top: 1.5rem;
					}
				}
			}
		}
	}
}
/*
 * NG-BOOTSTRAP END
 */

/*
 * RESPONSIVE DATA TABLES START
 */
//FOOTABLE
.table {
	&.footable {
		border: 1px solid $table-border-color;

		> thead,
		> tbody,
		> tfoot {
			> tr {
				th,
				td {
					border-color: $table-border-color;
				}
			}
		}

		> thead {
			> tr {
				> th {
					background-color: $table-header-background-color;
					border-bottom-width: 1px;
				}
			}
		}
	}
}

.footable-detail-row {
	> td {
		padding: 0 !important;
	}
	.footable-details {
		th {
			font-weight: 400;
			border-right: 1px solid $table-border-color;
		}

		> tbody {
			> tr:first-child {
				>th {
					border-right: 1px solid $table-border-color;
				}

				> th,
				> td {
					background-color: $table-header-background-color;
					border-top: 0;
					// font-weight: 600;
					font-weight: 700;
				}
			}

			> tr {
				> th:nth-child(1) {
					width: 134px;
				}
			}
		}
	}
}

table {
	&.responsive-data-table {
		border: 1px solid $table-border-color;
		width: 100%;
		padding: 0;
		border-collapse: collapse;
		border-spacing: 0;

		tr {
			padding: 5px;
		}

		th,
		td {
			padding: 8px;
			border: 1px solid $table-border-color;
			word-break: normal;
		}

		th {
			background-color: $table-header-background-color;
		}
	}
}

@media screen and (max-width: 479px) {
	table {
		&.responsive-data-table {
			border: 0;
			td {
				border: none;
			}
			tr {
				margin: 0;
				display: table-row;
				padding: 0;
			}
			tr:after {
				content: "";
				display: table;
				clear: both;
			}
			tr:before {
				display: block;
			}
			tr:not(:first-of-type) {
				td:first-of-type {
					border-top: none;
				}
			}
			td {
				box-sizing: border-box;
				display: table-cell;
				clear: left;
				text-align: center;
				border-bottom: 1px solid $table-border-color;
				border-right: 1px solid $table-border-color;
				border-left: 1px solid $table-border-color;
				border-top: 1px solid $table-border-color ;
				// &:last-child {
				// 	border-bottom: 1;
				// }
				&.responsive-availability {
					&:before {
						line-height: 36px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 320px) {
	table {
		&.responsive-data-table {
			td {
				&.responsive-availability {
					&:before {
						line-height: 54px;
					}
				}
			}
		}
	}
}
/*
 * RESPONSIVE DATA TABLES END
 */

/*
 * BOOTSTRAP 3 UTILS START
 */
@media (max-width: 575px) {
	.hidden-xs {
		display: none !important;
	}
}
@media (min-width: 576px) and (max-width: 767px) {
	.hidden-sm {
		display: none !important;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.hidden-md {
		display: none !important;
	}
}
@media (min-width: 992px) and (max-width: 1199px) {
	.hidden-lg {
		display: none !important;
	}
}
@media (min-width: 1200px) {
	.hidden-xl {
		display: none !important;
	}
}
/*
 * BOOTSTRAP 3 UTILS END
 */

/*
 * TWITTER BOOTSTRAP v3 POLYFILL
 */
.img-responsive {
	display: block;
	max-width: 100%;
	height: auto;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

/*
 * TWITTER BOOTSTRAP v4 OVERRIDE START
 */
.h-0 {
	height: 0 !important;
}

.input-group {
	&.dcsg-form-control-wrapper {
		height: 4rem;
		border: 0.1rem solid var(--hmf-gray300);

		.input-group-append,
		.input-group-prepend {
			& > .input-group-text {
				border: none;
				border-radius: 0;
				background-color: $form-control-background;
			}
		}
	}
}

.form-control {
	height: 100%;
	padding: 1rem;
	border: none;
	border-radius: 0;
	box-shadow: none;
	font-size: 1.6rem;
	line-height: 1.6rem;

	&:focus {
		border: none;
		box-shadow: none;
		outline: none;
	}

	&.number-input {
		text-align: center;
	}
}
/*
 * TWITTER BOOTSTRAP v4 OVERRIDE END
 */

.phoneNumber{
	color: $color-green-link;
	text-decoration: underline;
}

/*
 * MATERIAL OVERRIDES START
 */
.mat-form-field {
	font-family: $font-family-primary;
}

.mat-drawer-container {
	background-color: inherit;
}

.mat-drawer {
	z-index: 1000 !important;
}

.sidenav-container {
	border-top: solid 1px var(--hmf-gray300);
	//padding: $quarter-space 0;
	cursor: pointer;
	//margin-top: 10px;
	z-index: auto !important;

	.sidenav-content {
		overflow: hidden;
		transition: all 0.5s ease;

		&:hover {
			background-color: rgba(249,249,249,0.75);
		}
	}
}
/*
 * MATERIAL OVERRIDES END
 */

/*
 * MATERIAL SUPPLEMENTAL START
 */
.w-variable-sidenav-action {
	width: 100%;
	// Small devices (landscape phones, 576px and up)
	@media (min-width: 576px) {
		width: 100%;
	}

	// Medium devices (tablets, 768px and up)
	@media (min-width: 768px) {
		width: 80%;
	}

	// Large devices (desktops, 992px and up)
	@media (min-width: 992px) {
		width: 30%;
	}

	// Extra large devices (large desktops, 1200px and up)
	@media (min-width: 1200px) {
		width: 30%;
	}
}

.w-variable-sidenav-content {
	width: 100%;
	// Small devices (landscape phones, 576px and up)
	@media (min-width: 576px) {
		width: 100%;
	}

	// Medium devices (tablets, 768px and up)
	@media (min-width: 768px) {
		width: 80%;
	}

	// Large devices (desktops, 992px and up)
	@media (min-width: 992px) {
		width: 60%;
	}

	// Extra large devices (large desktops, 1200px and up)
	@media (min-width: 1200px) {
		width: 60%;
	}
}

.hide-box-shadow {
	.mat-drawer {
		&:not(.mat-drawer-side) {
			box-shadow: none;
		}
	}
}
/*
 * MATERIAL SUPPLEMENTAL END
 */

/*************** STATE STYLES ***************/

/*************** THEME STYLES ***************/
.standard-error-message {
	color: $error-message-red !important;
}

hr {
	border-top: 0.1rem solid var(--hmf-gray300);

	&.phr {
		border-top: 0.1rem solid gray;
		margin-top: $quarter-space;
		margin-bottom: $quarter-space;
	}
}

i {
	&.fa_star_half_custom {
		color: $color-light-gray !important;
	}

	&.fa_star_half_custom:after {
		content: "\f089";
		color: $star-ratings;
		margin: -1.2rem
	}
}

.star-rating {
	color: $color-light-gray;

	&:not(:first-of-type) {
		padding-left: 0.3rem;
	}

	&.star-filled {
		color: $star-ratings;
	}
}

/* Clearfix */
.clearfix:before,
.clearfix:after {
	content: " ";
	display: table;
}
.clearfix:after {
	clear: both;
}

/*
 * FLEX
 */
.flex-justify-content-center {
	justify-content: center !important;
}

/* Bold text */
.bold {
	font-weight: bold;
}

/* Italic text */
.italic {
	font-style: italic;
}

/* Underlined text */
.underline {
	text-decoration: underline;
}

/* Float element left */
.pull-left {
	float: left !important;
}

/* Float element right */
.pull-right {
	float: right !important;
}

/* Utility Margins */
.mt0 {
	margin-top: 0 !important;
}

.mt05 {
	margin-top: 0.5rem !important;
}

.mt10 {
	margin-top: 1rem !important;
}

.mt15 {
	margin-top: 1.5rem !important;
}

.mt20 {
	margin-top: 2rem !important;
}

.mt30 {
	margin-top: 3rem !important;
}

.mt40 {
	margin-top: 4rem !important;
}

.mt50 {
	margin-top: 5rem !important;
}

.mr0 {
	margin-right: 0 !important;
}

.mr05 {
	margin-right: 0.5rem !important;
}

.mr10 {
	margin-right: 1rem !important;
}

.mr20 {
	margin-right: 2rem !important;
}

.mr30 {
	margin-right: 3rem !important;
}

.mr40 {
	margin-right: 4rem !important;
}

.mr50 {
	margin-right: 5rem !important;
}

.mb0 {
	margin-bottom: 0 !important;
}

.mb05 {
	margin-bottom: 0.5rem !important;
}

.mb10 {
	margin-bottom: 1rem !important;
}

.mb20 {
	margin-bottom: 2rem !important;
}

.mb30 {
	margin-bottom: 3rem !important;
}

.mb40 {
	margin-bottom: 4rem !important;
}

.mb50 {
	margin-bottom: 5rem !important;
}

.ml0 {
	margin-left: 0 !important;
}

.ml05 {
	margin-left: 0.5rem !important;
}

.ml10 {
	margin-left: 1rem !important;
}

.ml20 {
	margin-left: 2rem !important;
}

.ml30 {
	margin-left: 3rem !important;
}

.ml40 {
	margin-left: 4rem !important;
}

.ml50 {
	margin-left: 5rem !important;
}

/* Utility Paddings */
.pt0 {
	padding-top: 0 !important;
}

.pt05 {
	padding-top: .5rem !important;
}

.pt10 {
	padding-top: 1rem !important;
}

.pt20 {
	padding-top: 2rem !important;
}

.pt30 {
	padding-top: 3rem !important;
}

.pt40 {
	padding-top: 4rem !important;
}

.pt50 {
	padding-top: 5rem !important;
}

.pr0 {
	padding-right: 0 !important;
}

.pr10 {
	padding-right: 1rem !important;
}

.pr20 {
	padding-right: 2rem !important;
}

.pr30 {
	padding-right: 3rem !important;
}

.pr40 {
	padding-right: 4rem !important;
}

.pr50 {
	padding-right: 5rem !important;
}

.pb10 {
	padding-bottom: 1rem !important;
}

.pb20 {
	padding-bottom: 2rem !important;
}

.pb30 {
	padding-bottom: 3rem !important;
}

.pb40 {
	padding-bottom: 4rem !important;
}

.pb50 {
	padding-bottom: 5rem !important;
}

.pl0 {
	padding-left: 0 !important;
}

.pl05 {
	padding-left: .5rem !important;
}

.pl10 {
	padding-left: 1rem !important;
}

.pl20 {
	padding-left: 2rem !important;
}

.pl30 {
	padding-left: 3rem !important;
}

.pl40 {
	padding-left: 4rem !important;
}

.pl50 {
	padding-left: 5rem !important;
}

//Header
header {
	padding: 10px 0;
	width: 100%;
}

//Common Heading Style
.title {
	margin-bottom: 0;
	font-size: $font-size-md-3;
	font-weight: 400;
	line-height: 1.25;
	letter-spacing: 0.8px;

	@media screen and (min-width: 768px) {
		font-size: $font-size-lg;
		line-height: 1;
		text-align: left !important;
	}

	@media screen and (min-width: 992px) {
		font-size: $font-size-lg-2;
		line-height: 1;
		letter-spacing: 0.7px;
	}
}

//BreadCrumbs
.breadcrumbs {
	li {
		float: left;
		text-decoration: none;
		color: #5E6260;
		font-size: $font-size-xs-3;
		letter-spacing: 0.5px;
		i {
			font-size: $font-size-xs-3;
			float: left;
			padding: 3px 5px 0 5px;
			color: #5E6260 !important;
		}

		&.breadcrumb-link:hover {
			text-decoration: underline;
			color: $breadcrumb-link-hover !important;
		}
		&.breadcrumb-link:active {
			text-decoration: underline;
			color: $breadcrumb-link-active;
		}
		&.breadcrumb-link:visited {
			color: #5E6260 !important;
		}
	}
}

//Promotion Text
.product-promotion-container {
	a.promotion-description-text {
		cursor: pointer;
		@include pdp-promo-details-text;
		&:hover {
			text-decoration: underline;
		}
	}
}

//Common link:
.inline-link {
	color: $color-green-link;
}

.overflow-hidden {
	overflow: hidden !important;
	height: 100%;
	margin: 0;
	padding: 0;
}

.filter {
	filter: blur(5px);
	-webkit-filter: blur(5px);

	@media screen and (min-width: 768px) {
		filter: none;
		-webkit-filter: none;
	}
}

.no-scroll {
	@media screen and (max-width: 767px) {
		height: 170vh;
	}

	@media screen and (max-width: 370px) {
		height: 180vh;
	}

	overflow-x: hidden;
	overflow-y: scroll;

	body {
		height: 170vh;
		@media screen and (max-width: 370px) {
			height: 180vh;
		}

		overflow: hidden;
		-webkit-overflow-scrolling: touch;
	}
}


.font-normal {
	font-weight: normal;
}

.search-content > div > div ul li {
	&:hover {
		.font-normal {
			font-weight: bold;
		}
	}
}

@keyframes fadeIn {
	0% {
		opacity:0;
	}
	100% {
		opacity:1;
	}
}

@-moz-keyframes fadeIn {
	0% {
		opacity:0;
	}
	100% {
		opacity:1;
	}
}

@-webkit-keyframes fadeIn {
	0% {
		opacity:0;
	}
	100% {
		opacity:1;
	}
}

@-o-keyframes fadeIn {
	0% {
		opacity:0;
	}
	100% {
		opacity:1;
	}
}

@-ms-keyframes fadeIn {
	0% {
		opacity:0;
	}
	100% {
		opacity:1;
	}
}

.no-padding {
	padding: 0 !important;
}

.no-pointer {
	cursor: default !important;
}

.common-side-nav:focus {
	outline: 0;
}
