/** @format */
@use '../../utils/_' as utils;

@import '../../tokens/text/';

@mixin font-sizeByType($size, $type: 'body') {
	font-size: utils.ds-map-getX3($tokens-textTypes, $type, $size, fontSize);
}

@mixin line-heightByType($size, $type: 'body') {
	line-height: utils.ds-map-getX3($tokens-textTypes, $type, $size, lineHeight);
}

@mixin font-weight($size) {
	font-weight: map.get($tokens-textWeight, $size);
}

@mixin font-weightByType($type: 'body') {
	font-weight: utils.ds-map-getX2($tokens-textTypes, $type, fontWeight);
}

@mixin font-familyByType($type: 'body') {
	font-family: utils.ds-map-getX2($tokens-textTypes, $type, fontFamily);
}

@mixin letter-spacingByType($type: 'body') {
	letter-spacing: utils.ds-map-getX2($tokens-textTypes, $type, letterSpacing);
}

@mixin font($size, $type: 'body') {
	// Having all these rules might not be necessary, but for theming might be ideal to be verbose.
	// With componentization these shouldn't be used all that often, so having many rules should be okay.
	@include font-sizeByType($size, $type);
	@include line-heightByType($size, $type);
	@include font-weightByType($type);
	@include font-familyByType($type);
	@include letter-spacingByType($type);
}
