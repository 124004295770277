/* SUBHEADINGS */
// global
$subhead-letter-spacing: 0.5px;
// s
$subhead-font-size-s: 12;
$subhead-line-height-s: 16;
// m
$subhead-font-size-m: 14;
$subhead-line-height-m: 18;
// l
$subhead-font-size-l: 16;
$subhead-line-height-l: 20;
