/* LEGAL */
// global
$legal-letter-spacing: 0.5px;
// xs
$legal-font-size-xs: 11;
$legal-line-height-xs: 16;
// s
$legal-font-size-s: 12;
$legal-line-height-s: 16;

