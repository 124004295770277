/** @format */

@use 'sass:map';
@use '../../themes/calia/palette' as calia;
@use '../../themes/default/palette' as dsg;
@use '../../themes/g3/palette' as g3;
@use '../../themes/gg/palette' as gg;
@use '../../themes/pl/palette' as pl;
@use '../../themes/mj/palette' as mj;
@use '../../themes/vrst/palette' as vrst;

$theme-palettes: (
	'calia': calia.$palette,
	'dsg': dsg.$palette,
	'g3': g3.$palette,
	'gg': gg.$palette,
	'pl': pl.$palette,
	'mj': mj.$palette,
	'vrst': vrst.$palette
);

@function get-palette($chain) {
	@return map.get($theme-palettes, $chain);
}

@function ds-getColor($color, $level: 'base', $value: '500', $chain: 'dsg') {
	$_paletteMap: get-palette($chain);

	@return map.get($_paletteMap, $color, $level, $value);
}

@mixin background-color($value: '50', $chain: 'dsg') {
	background-color: ds-getColor('gray', 'base', $value, $chain);
}
