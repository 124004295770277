/** @format */
@use 'sass:map';
@use './variables' as *;

@function ds-remCalc($size, $baseSize: $ds-utils-baseTextSize) {
	$_size: $size;
	@if (unit($size) != 'px') {
		@error 'ds-remCalc() requires a pixel value for the first parameter';
	}

	// Unit conversion to plain number
	@if (unit($size) == 'px') {
		$_size: $size / 1px;
	}

	$remSize: $_size / $baseSize;
	@return #{$remSize}rem;
}

@function map-fetch($map, $keys...) {
	@each $key in $keys {
		$map: map.get($map, $key);
	}
	@return $map;
}

@function ds-map-getX2($baseMap, $firstSelection, $secondSelection) {
	$_secondMap: map.get($baseMap, $firstSelection);
	@return map.get($_secondMap, $secondSelection);
}

@function ds-map-getX3($baseMap, $firstSelection, $secondSelection, $thirdSelection) {
	$_secondMap: map.get($baseMap, $firstSelection);
	$_thirdMap: map.get($_secondMap, $secondSelection);
	@return map.get($_thirdMap, $thirdSelection);
}

// Minimum breakpoint width. Null for the smallest (first) breakpoint.
//
//    >> breakpoint-min(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    576px
@function breakpoint-min($name, $breakpoints: $homefield-breakpoints) {
	$min: map.get($breakpoints, $name);
	@return if($min != 0px, $min, null);
}

// Maximum breakpoint width. Null for the smallest (first) breakpoint.
//
//    >> breakpoint-max(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
@function breakpoint-max($name, $breakpoints: $homefield-breakpoints) {
	$max: map.get($breakpoints, $name);
	@return if($max != 0px, $max, null);
}

// Returns a blank string if smallest breakpoint, otherwise returns the name with a dash infront.
// Useful for making responsive utilities.
//
//    >> breakpoint-infix(xs, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    ""  (Returns a blank string)
//    >> breakpoint-infix(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    "-sm"
@function breakpoint-infix($name, $breakpoints: $homefield-breakpoints) {
	@return if(breakpoint-min($name, $breakpoints) ==null, '', '-#{$name}');
}
