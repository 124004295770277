/* You can add global styles to this file, and also import other style files */
/*************** IMPORT STYLES ***************/
// Chain Specific Variables
@import 'styles/chain-vars';

/*
 * Custom Elements
 */
clp-root {
	display: block;
}

.mat-form-field-outline-start, .mat-form-field-outline-end {
    border-radius: 0 !important;
}
