/**
 * COMMON UI COLOR PALETTE
 */
//Standard hex colors
$color-red: #c60000;

$color-dsg-deep-orange: #ef6802;
$color-dsg-orange: #f8972a;
$color-gold: #f6a01a;
$color-green: #008000;
$color-green-light: #00856d;
$color-green-success: #006554;
$color-gray-dark-6: #5e6260;
$color-dsg-black-2: #3e3e3f;

$color-green-link: #235c75;
$color-orange-dark: #af5700;
$color-gray-light: #f1f1f1;
$color-green-light: #027256;


//Standard common hex variation colors
$color-gtgt-blue: #1e3454;

//Standard common swatch colors
$color-gold-account: #9E8F64;
$color-gold-background: #F2EBD9;

// paypal colors
$color-paypal-yellow: #f6c557;

/**
 * COMMON UI COLOR ALIASES
 */
// Theme color palette chain
// $theme-color-chain: 'gg'; Provided in chain_vars now
$theme-primary-color: var(--hmf-gray900);
$form-control-background: var(--hmf-gray50);
$primary-font-color: var(--hmf-gray900);
$primary-link-color: var(--hmf-gray200);
$star-ratings: $color-gold;
$color-light-gray: var(--hmf-gray200);
$swatch-border: $color-light-gray;
$swatch-selected-border: var(--hmf-gray900);
$error-message-red: $color-red;
$modal-header-background: var(--hmf-gray200);
$modal-header-border-bottom: var(--hmf-gray300);

/**
 * COMPONENTS
 */
$btn-border-radius: 0;
$btn-background-primary: $color-gold;
$btn-border-primary: none;
$btn-font-color-primary: var(--hmf-gray50);
$btn-background-secondary-hover: var(--hmf-gray50);
$btn-border-secondary: 1px solid var(--hmf-gray900);
$btn-border-secondary-hover: 1px solid var(--hmf-gray500);
$btn-border-secondary-active: 2px solid var(--hmf-gray900);
$btn-background-secondary: var(--hmf-gray100);
$btn-font-color-secondary: var(--hmf-gray200);
$btn-gradient-top-primary: $btn-background-primary;
$btn-gradient-bottom-primary: $btn-background-primary;
$btn-gradient-top-secondary: $btn-background-secondary;
$btn-gradient-bottom-secondary: $btn-background-secondary;
$btn-font-weight-large: 700;
$nav-menu-header-border-bottom: var(--hmf-gray900);
$hyperlink-color-primary: var(--hmf-gray900);
$hyperlink-color-primary-hover: var(--hmf-gray900);
$hyperlink-color-primary-active: var(--hmf-gray900);
$hyperlink-color-primary-visited: var(--hmf-gray900);
$mini-cart-count-background-color: $color-dsg-deep-orange;
$progress-spinner-stroke-color-primary: $color-green;
$progress-spinner-stroke-color-secondary: var(--hmf-gray50);
$header-border-top: var(--hmf-gray900);
$header-top-ticker: var(--hmf-gray900);
$table-border-color: var(--hmf-gray300);
$table-header-background-color: var(--hmf-gray100);
$swatch-border-color-hover: var(--hmf-gray500);
$snackbar-border-top: 3px solid var(--hmf-gray900);
$gtgt-color: $color-gtgt-blue;
$gtgt-font-family: 'DSG Sans Bold', sans-serif;
$delivery-heading-color: $color-green-success;
$confirmation-header-color: $color-green-success;
$shadow-color: #A37859;
$new-fullfillment-disabled-color: #F0EDE8;
$new-confirm-icon-color: #008d75;
$new-cancel-icon-color: #910208;
$new-warning-icon-color:  #92694E;
$scorecard-gold: #C3AD71;
$plp-filter-column-lg-width: 324px;

// font families
$dsg-sans-bold: 'DSG Sans Bold';
$dsg-sans-medium: 'DSG Sans Medium';
$dsg-sans-black: 'DSG Sans Black';
$archivo: 'Archivo';

/**
 * COMMON TYPOGRAPHY
 */
//Spacing
$full-space: 40px;
$two-thirds-space: 25px;
$six-tenths-space: 24px;
$half-space: 20px;
$fourth-space: 16px;
$third-space: 15px;
$quarter-space: 10px;
$empty-space: 0;

// Media Queries
$desktop-large: 'min-width: 1281px';
$desktop-1200: 'min-width: 1200px';
$desktop-md: 'min-width:992px';
$desktop: 'min-width: 767px';
$mobile: 'max-width: 767px';
$xs: 'max-width: 576px';

//Font sizing
$font-size-xs: 1rem;
$font-size-xs-2: 1.1rem;
$font-size-xs-3: 1.2rem;
$font-size-sm: 1.3rem;
$font-size-sm-2: 1.4rem;
$font-size-md: 1.6rem;
$font-size-md-2: 1.7rem;
$font-size-md-3: 1.8rem;
$font-size-lg: 2rem;
$font-size-lg-2: 2.2rem;
$font-size-lg-3: 2.4rem;
$font-size-xl: 3.2rem;
$font-size-xl-2: 3.6rem;
$font-size-xl-3: 4rem;
$font-size-base: $font-size-sm;

// Letter Spacing
$letter-spacing-xs: 0.5px;
$letter-spacing-sm: 1px;
$letter-spacing-md: 2px;
$letter-spacing-lg: 4px;
$letter-spacing-default: $letter-spacing-xs;

// Line Heights
$lh-10: 1rem;
$lh-12: 1.2rem;
$lh-14: 1.4rem;
$lh-16: 1.6rem;
$lh-18: 1.8rem;
$lh-20: 2rem;
$lh-24: 2.4rem;
$lh-28: 2.8rem;
$lh-34: 3.4rem;
$lh-36: 3.6rem;
$lh-50: 5rem;
$lh-68: 6.8rem;

/**
 * COMMON UTILITIES
 */
//Transition duration
$transition-duration-short: 0.35s;
$transition-duration-xshort: 0.25s;

$color-green-avl: #027256;

$bopis-letter-spacing: 0.5px;

$font-style-normal: normal;
$font-weight-bold: bold;
$font-size: 14px;
$line-height: 24px;
//Material Input CSS
$inactive-border: 1px solid #e3e4e4;
$focused-border: 1px solid var(--hmf-gray900);
$visitied-border: 1px solid #babcbb;
$hover-border: 1px solid #babcbb;
$disabled-border: 1px solid #e3e4e4;

$inactive-text: #5e6260;
$focused-text: var(--hmf-gray900);
$visitied-text: #babcbb;
$hover-text: #babcbb;
$disabled-text: var(--hmf-gray900);


//new css
$primary-font-family: 'Helvetica', 'sans-serif';

/* SNACKBAR */
$snackbar-heading-font: $primary-font-family;
$snackbar-heading-letter-spacing: $letter-spacing-default;

/* BUTTONS */
@mixin btn-monorepo {
	box-sizing: border-box;
	text-transform: uppercase;
	padding: 0 2.4rem;
	min-height: 7rem;
	max-height: 7rem;
	cursor: pointer;
	position: relative;
	margin: 0;
}

/* CARD */
// Box shadow
$dcsg-card-box-shadow-color-color: 'gray';
$dcsg-card-box-shadow-color-level: 'base';
$dcsg-card-box-shadow-color-value: '500';

// Additional calia vars
$color-calia-light-pink-base: #f6ddda;
$color-calia-purple-base: #44283b;
$color-calia-grey-base: var(--hmf-gray900);
$color-calia-teal-base: #00687e;
$color-calia-green-base: #49a87b;
$color-calia-dark-pink-base: #eb004e;
$color-calia-light-pink-base-2: #fbeeed;
$color-calia-grey-base-3: #c9c5c3;
$color-calia-purple-base-4: #604858;
$color-calia-grey-base-1: #f9f8f8;
$color-calia-grey-base-2: #f5f3f3;
$color-calia-grey-base-4: #757070;

// Footer v2
// $footer-banner-background: var(--hmf-gray900);
$footer-text-color: var(--hmf-gray900);
$footer-default-font: 'Archivo', Arial, sans-serif;
$footer-heading-font1: 'Archivo', Arial, sans-serif;
$footer-heading-font2: 'DSG Sans Black', Arial, sans-serif;
$border-banner: #e3e4e4;
$footer-heading-font-weight: 700;
$footer-heading-font-weight2: 700;
$footer-signup-btn-color: #bb5811;
$footer-signup-btn-hover: #ac470c;
$footer-signup-btn-active: #c57135;
$footer-signup-btn-focus: #bb5811;
$footer-link-hover: #bb5811;
$footer-link-active: #bb5811;
$footer-link-visited: #5e6260;

//Error red for error icons
$error-red-icon-svg-filter: invert(13%) sepia(79%) saturate(6088%) hue-rotate(16deg) brightness(95%) contrast(108%);

/* SVGs */
// Error color for SVGs
$svg-filter-error-icon-color: invert(33%) sepia(36%) saturate(6670%) hue-rotate(337deg) brightness(67%) contrast(128%);

// Info color for SVGs
$svg-filter-info-icon-color: invert(12%) sepia(86%) saturate(867%) hue-rotate(177deg) brightness(99%) contrast(94%);

// Warn color for SVGs
$svg-filter-warn-icon-color: invert(71%) sepia(87%) saturate(714%) hue-rotate(350deg) brightness(102%) contrast(101%);

// Success color for SVGs
$svg-filter-success-icon-color: invert(37%) sepia(23%) saturate(2502%) hue-rotate(130deg) brightness(101%) contrast(102%);

/* ALERT MESSAGES */
// Error background color
$dsg-alert-error-background-color-color: 'red';
$dsg-alert-error-background-color-level: 'base';
$dsg-alert-error-background-color-value: '50';

// Error background left border
$dsg-alert-error-border-color-color: 'red';
$dsg-alert-error-border-color-level: 'base';
$dsg-alert-error-border-color-value: '500';

// Error Icon fill
$dsg-alert-error-icon-fill-color: var(--hmf-red500);

// Info background color
$dsg-alert-info-background-color-color: 'blue';
$dsg-alert-info-background-color-level: 'base';
$dsg-alert-info-background-color-value: '50';

// Info background left border
$dsg-alert-info-border-color-color: 'blue';
$dsg-alert-info-border-color-level: 'base';
$dsg-alert-info-border-color-value: '500';

// Info Icon fill
$dsg-alert-info-icon-fill-color: var(--hmf-tertiary500);

// Success background color
$dsg-alert-success-background-color-color: 'green';
$dsg-alert-success-background-color-level: 'base';
$dsg-alert-success-background-color-value: '50';

// Success background left border
$dsg-alert-success-border-color-color: 'green';
$dsg-alert-success-border-color-level: 'base';
$dsg-alert-success-border-color-value: '500';

// Success Icon fill
$dsg-alert-success-icon-fill-color-color: 'green';
$dsg-alert-success-icon-fill-color-level: 'base';
$dsg-alert-success-icon-fill-color-value: '500';

// Warn background color
$dsg-alert-warn-background-color-color: 'yellow';
$dsg-alert-warn-background-color-level: 'base';
$dsg-alert-warn-background-color-value: '50';

// Warn background left border
$dsg-alert-warn-border-color-color: 'yellow';
$dsg-alert-warn-border-color-level: 'base';
$dsg-alert-warn-border-color-value: '500';

// Warn Icon fill
$dsg-alert-warn-icon-fill-color-color: 'yellow';
$dsg-alert-warn-icon-fill-color-level: 'base';
$dsg-alert-warn-icon-fill-color-value: '500';

// Warn Icon fill (standalone)
$dsg-alert-warn-icon-standalone-fill-color-color: 'yellow';
$dsg-alert-warn-icon-standalone-fill-color-level: 'base';
$dsg-alert-warn-icon-standalone-fill-color-value: '900';

// Warn Icon Success fill
$dsg-alert-warn-icon-success-fill-color-color: 'green';
$dsg-alert-warn-icon-success-fill-color-level: 'base';
$dsg-alert-warn-icon-success-fill-color-value: '50';


// store layer error text
$dsg-alert-error-text-color-value: '700';

//nike exclusive colors
$nike-background: #F1EBDD;
$nike-green: #27DEB1;
