/* DISPLAY TEXT */
// global
$display-text-transform: uppercase;
// xs
$display-font-size-xs: 24;
$display-letter-spacing-xs: 1px;
$display-font-line-height-xs: 28;
// s
$display-font-size-s: 32;
$display-letter-spacing-s: 1px;
$display-font-line-height-s: 34;
// m
$display-font-size-m: 48;
$display-letter-spacing-m: 2px;
$display-font-line-height-m: 50;
// l
$display-font-size-l: 64;
$display-letter-spacing-l: 2px;
$display-font-line-height-l: 68;
// xl
$display-font-size-xl: 140;
$display-letter-spacing-xl: 1px;
$display-font-line-height-xl: 144;
