/*********** TYPOGRAPHY ***********/
// Set Fonts
$font-display: 'DSG Sans Black';
$font-primary: 'Archivo';

// Font Weights
$font-primary-regular-weight: 500;
$font-primary-bold-weight: 700;
$font-display-weight: 500;

/*********** TYPOGRAPHY TYPES ***********/
@import 'body';
@import 'display';
@import 'headings';
@import 'labels';
@import 'legal';
@import 'links';
@import 'subheading';

/*********** TYPOGRAPHY MIXINS ***********/
@import '../../../styles/text/';
