@use '../../styles/color/' as palette;

/*********** VARIABLES ***********/
$chain: 'dsg';

/*********** GENERAL STYLES ***********/
$hmf-focus-outline: 1px dashed palette.ds-getColor('green', 'base', '500', $chain);
$hmf-border-radius-sharp: 0px;
$hmf-border-radius-soft: 4px;
$hmf-border-radius-softer: 8px;
$hmf-border-radius-softest: 16px;
$hmf-border-radius-round: 32px;
