/** @format */
.text {
	color: $primary-font-color;

	&--subtext {
		@include subTextFont;
		color: $description-text-color;
		font-family: $font-family-primary;
		@if ($theme-color-chain == 'pl') {
			font-weight: 700;
		}
		font-weight: normal;
		text-transform: $subtext-text-transform;
	}

	&--title {
		@include titleHeaderFont;
		line-height: 1;
		text-transform: $section-header-text-transform;
		@if ($theme-color-chain != 'pl') {
			font-family: $hp-title-font;
		}
		font-weight: $hp-title-font-weight;
		color: $hp-font-color;
	}

	&--copyboxTitle {
		@include copyBoxHeaderFont;
		text-transform: $section-header-text-transform;
	}

	&--subhead {
		@include subHeaderFont;
		letter-spacing: 1px;
		line-height: 1;
		text-transform: $section-header-text-transform;
		font-family: $hp-title-font;
		font-weight: $hp-title-font-weight;
	}

	&--categories-header {
		color: $hp-font-color;
		font-family: $hp-title-font;
		@if ($theme-color-chain == 'pl') {
			@include heading-lg;
			font-weight: 700 !important;
		} @else {
			@include subTextFont;
			font-weight: $hp-title-font-weight;
		}
	}

	&--center {
		text-align: center;
	}

	&--left {
		text-align: left;
	}

	&--right {
		text-align: right;
	}

	&--accordion {
		font-family: $accordion-font-family-header;
		@include subhead-reg-md;

		&__text {
			font-weight: normal;
			font-family: $accordion-font-family-text;
			@include body-reg-md;
		}
	}

	&--badge-primary {
		background-color: $badge-background-default;
		color: $badge-foreground-primary;
		font-family: $badge-font-family; //$badge-font-family differs on GG
		font-size: $font-size-md-2;
		font-weight: $headline-font-weight;
	}

	&--hype-hero-headline {
		@include heroHeaderFont;
		letter-spacing: 1px;
		font-family: $hp-title-font;
		font-weight: $hp-title-font-weight;
		line-height: 1;
	}
}
