/** @format */
@use '../../utils/_' as utils;

@mixin padding($first, $second: null, $third: null, $forth: null) {
	@if ($second == null) {
		$_first: utils.ds-getSpace($first);
		padding: $_first;
	} @else if ($third == null) {
		$_first: utils.ds-getSpace($first);
		$_second: utils.ds-getSpace($second);
		padding: $_first $_second;
	} @else if ($forth == null) {
		$_first: utils.ds-getSpace($first);
		$_second: utils.ds-getSpace($second);
		$_third: utils.ds-getSpace($third);
		padding: $_first $_second $_third;
	} @else {
		$_first: utils.ds-getSpace($first);
		$_second: utils.ds-getSpace($second);
		$_third: utils.ds-getSpace($third);
		$_forth: utils.ds-getSpace($forth);
		padding: $_first $_second $_third $_forth;
	}
}

@mixin padding-top($size) {
	padding-top: utils.ds-getSpace($size);
}

@mixin padding-right($size) {
	padding-right: utils.ds-getSpace($size);
}

@mixin padding-bottom($size) {
	padding-bottom: utils.ds-getSpace($size);
}

@mixin padding-left($size) {
	padding-left: utils.ds-getSpace($size);
}

@mixin padding-vertical($size, $size2: null) {
	@if ($size2 == null) {
		@include padding-top($size);
		@include padding-bottom($size);
	} @else {
		@include padding-top($size);
		@include padding-bottom($size2);
	}
}

@mixin padding-horizontal($size, $size2: null) {
	@if ($size2 == null) {
		@include padding-left($size);
		@include padding-right($size);
	} @else {
		@include padding-left($size);
		@include padding-right($size2);
	}
}
