/* HEADINGS */
// global
$heading-text-transform: none;
// xs
$heading-font-size-xs: 14;
$heading-letter-spacing-xs: 0.5px;
$heading-font-line-height-xs: 18;
// s
$heading-font-size-s: 16;
$heading-letter-spacing-s: 0.5px;
$heading-font-line-height-s: 20;
// m
$heading-font-size-m: 20;
$heading-letter-spacing-m: 0.5px;
$heading-font-line-height-m: 24;
// l
$heading-font-size-l: 24;
$heading-letter-spacing-l: 0.5px;
$heading-font-line-height-l: 28;
// xl
$heading-font-size-xl: 32;
$heading-letter-spacing-xl: 0.5px;
$heading-font-line-height-xl: 37;
