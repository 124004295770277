/* LABELS */
// global
$label-letter-spacing: 1.5px;
$label-text-transform: uppercase;
// xs
$label-font-size-height-xs: 10;
// s
$label-font-size-height-s: 12;
// m
$label-font-size-height-m: 14;
// l
$label-font-size-height-l: 16;
// xl
$label-font-size-height-xl: 18;
