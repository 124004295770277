/**
 * /* You can add global styles to this file, and also import other style files
 *
 * @format
 */

/*************** IMPORT STYLES ***************/
// Chain Specific Variables
@use 'styles/chain-vars' as *;
@forward 'styles/chain-vars';

/*
 * lightGallery.js overrides
 */
.lg-actions {
  .lg-next, .lg-prev {
	  background-color: rgba(0, 0, 0, 0);
  }
}

.lg-backdrop {
	background-color: var(--hmf-gray50);
}

.lg-sub-html,
.lg-toolbar {
	background-color: var(--hmf-gray50);
}

.lg-outer {
  .lg-thumb-outer {
	  background-color: #f1f1f1;
  }

  .lg-toogle-thumb {
    right: 0;
    background-color: #f1f1f1;
  }

  .lg-img-wrap {
    padding: 4.5rem;
  
    @media screen and (min-width: 768px) {
      padding-bottom: 27.5% !important;
    }
  
    @media screen and (min-width: 1024px) {
      padding-bottom: 17.5% !important;
    }
  
    @media screen and (min-width: 1300px) {
      padding-bottom: 10.5% !important;
    }
  }

  .lg-has-html5 {
    .lg-video-play {
      background: none;
  
      &:after {
        display: block;
        font-family: 'Material-Icons';
        content: 'play_circle_outline';
        pointer-events: none;
        position: absolute;
        top: calc(50% - 4rem);
        left: calc(50% - 3rem);
        color: var(--hmf-gray50);
        font-size: 60px;
        text-shadow: 0 0.28571rem 0.28571rem rgba(0, 0, 0, 0.5);
        font-feature-settings: 'liga' !important;
      }
    }
  }

  .lg-video {
    display: inline-block;
    height: 100%;
    padding: 4.5rem;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  
    &:before {
      content: '';
      display: inline-block;
      height: 50%;
      width: 1px;
      margin-right: -1px;
    }
  
    @media screen and (min-width: 768px) {
      padding-bottom: 27.5% !important;
    }
  
    @media screen and (min-width: 1024px) {
      padding-bottom: 17.5% !important;
    }
  
    @media screen and (min-width: 1300px) {
      padding-bottom: 10.5% !important;
    }

    .lg-object {
      position: initial;
      top: initial;
      left: initial;
      height: auto !important;
      width: auto !important;
      max-width: 100%;
      max-height: 100%;
      vertical-align: middle;
    }

    .lg-video-play {
      top: 50%;
    
      @media screen and (min-width: 768px) {
        top: 37%;
      }
    
      @media screen and (min-width: 1024px) {
        top: 40%;
      }
    }
  }
  .lg-video-object {
    position: unset;
    top: unset;
    left: unset;
  }
}


.lg-outer .lg-toogle-thumb:hover,
.lg-outer.lg-dropdown-active #lg-share {
	color: #999;
}

.lg-actions {
   .lg-prev {
	  left: 0;

    &:after {
      content: 'keyboard_arrow_left';
    }
   }

   .lg-next {
    right: 0;

    &:before {
      content: 'keyboard_arrow_right';
    }
   }

   .lg-prev:after,
   .lg-next:before {
      font-family: 'Material-Icons';
      color: #a7a7a7;
      font-size: 4.4rem;
   }

  .lg-prev, .lg-next {
    &:hover {
      color: #999;
    }
  }
}

.lg-icon {
	&.lg-close {
		font-size: 4rem;
		color: var(--hmf-gray900);
	}
}

.lg-toolbar .lg-icon:hover {
	color: #999;
}

.lg-outer {
  .lg-thumb-item {
    border-radius: 0;
    border: 1px solid var(--hmf-gray100);
  }

  .lg-thumb-item.active,
  .lg-thumb-item:hover {
    border: none;
    border-bottom: 3px solid $swatch-selected-border;
  }
}

.lg-image-wrap {
	@media screen and (min-width: 768px) {
		padding-bottom: 20% !important;
	}
}

.lg-thumb-item {
	@media screen and (min-width: 768px) {
		background-color: var(--hmf-gray50);

    img {
      object-fit: contain !important;
    }
	}
}

.lg-fullscreen {
	display: none;
}

// IE Override
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.slick-prev:before,
	.slick-next:before,
	.lg-actions .lg-prev:after,
	.lg-actions .lg-next:before {
		font-feature-settings: 'liga' !important;
	}

	// Zoom image viewer fixes
	.lg-thumb-outer {
		height: 146px !important;
	}

	.lg-thumb-item {
		text-align: center;

		img {
			height: auto !important;
			max-height: 100% !important;
			width: auto !important;
			max-width: 100% !important;
		}
	}
}
/*************** PRODUCT COPY STYLES (MDM DESCRIPTION FIELD) -- END ***************/

/*************** Bizaar Voice Overrides -- START ***************/

.bv-curations-cleanslate.bv-carousel-wrapper.bv-minimalist .fm-header .fm-title .fm-float-left {
	width: 100% !important;
  
  .fm-title {
    font-family: $primary-font-family !important;
    font-size: 2rem !important;
    font-weight: 500 !important;
    letter-spacing: 2px !important;
    line-height: 2.4rem !important;
	 
	span {
		font-family: $primary-font-family !important;
		font-size: 1.4rem !important;
		font-weight: 500 !important;
		letter-spacing: 1px !important;
		line-height: 1.8rem !important;
	}
  }
}

/*************** Bizaar Voice Overrides -- END ***************/

.mat-drawer-container {
	z-index: 999 !important;
}

/*************** Material Input Overrides -- START ***************/
.mat-form-field-outline-start,
.mat-form-field-outline-end {
	border-radius: 0 !important;
}

@mixin material-form-field-outline-start-end($border, $focused) {
  .mat-form-field-outline-gap {
	  border-top: $focused;
		border-bottom: $border !important;
	}

	.mat-form-field-outline-start {
		border-left: $border !important;
		border-top: $border !important;
		border-bottom: $border !important;
	}

	.mat-form-field-outline-end {
		border-top: $border !important;
		border-bottom: $border !important;
		border-right: $border !important;
	}
}

@mixin material-input-border($border) {
  @include material-form-field-outline-start-end($border, $border);
}

@mixin material-input-border-focused($border) {
  @include material-form-field-outline-start-end($border, none);
}

// inactive---------------------------------
.mat-form-field:not(.mat-focused):not(.mat-form-field-disabled) {
	@include material-input-border-focused($inactive-border);

	.mat-form-field-required-marker {
		color: $inactive-mat-text !important;
	}

	.mat-form-field-label {
		color: $inactive-mat-text !important;
	}

	.mat-form-field-outline {
		background: var(--hmf-gray50);
	}

	&:hover {
		@include material-input-border($hover-border);
		caret-color: $hover-text !important;
	}

  &:not(.mat-form-field-should-float) {
    @include material-input-border($inactive-border);

    .mat-form-field-required-marker {
      color: $inactive-mat-text !important;
    }

    .mat-form-field-label {
      color: $inactive-mat-text !important;
    }

    .mat-form-field-outline {
      background: var(--hmf-gray50);
    }

    &:hover {
      @include material-input-border($hover-border);
      caret-color: $hover-text !important;
    }
  }
}

// focused----------------------------------
.mat-focused .mat-form-field-flex {
	@include material-input-border-focused($focused-border);

	.mat-form-field-required-marker {
		color: $focused-mat-text !important;
	}

	.mat-form-field-label {
		color: $focused-mat-text !important;
	}

	.mat-form-field-outline {
		background: var(--hmf-gray50);
	}
}
// error------------------------------------
.ng-invalid:not(.ng-untouched):not(.mat-focused):not(.mat-form-field-should-float) .mat-form-field-flex,
.footer-calia.ng-invalid:not(.ng-untouched):not(.mat-focused):not(.mat-form-field-should-float) .mat-form-field-flex {
	@include material-input-border(var(--hmf-border-negative));
	.mat-form-field-required-marker {
		color: var(--hmf-label-negative) !important;
	}
	.mat-form-field-label {
		color: var(--hmf-label-negative) !important;
	}
	.mat-form-field-outline {
		background: var(--hmf-gray50);
	}
}

.mat-focused.ng-invalid:not(.ng-untouched) .mat-form-field-flex {
	@include material-input-border-focused(var(--hmf-border-negative));
	.mat-form-field-required-marker {
		color: var(--hmf-label-negative) !important;
	}
	.mat-form-field-label {
		color: var(--hmf-label-negative) !important;
	}
	.mat-form-field-outline {
		background: var(--hmf-gray50);
	}
}

.mat-form-field-should-float.ng-invalid:not(.ng-untouched) .mat-form-field-flex {
	@include material-input-border-focused(var(--hmf-border-negative));
	.mat-form-field-required-marker {
		color: var(--hmf-label-negative) !important;
	}
	.mat-form-field-label {
		color: var(--hmf-label-negative) !important;
	}
	.mat-form-field-outline {
		background: var(--hmf-gray50);
	}
}

// disabled-----------------------------------
.mat-form-field-disabled {
	@include material-input-border($disabled-border);
	.mat-form-field-required-marker {
		color: $inactive-text !important;
	}
	.mat-form-field-label {
		color: $inactive-text !important;
	}
	.mat-form-field-outline {
		background: #eaebeb !important;
	}
}
/*************** Material Input Overrides -- END ***************/
