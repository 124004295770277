// Import base variables
@import 'common-vars';
@import 'homefield-theme-default';

/**
*STOP!!!!! PLEASE READ BEFORE CONTINUING
*
* DO NOT USE VARIABLES IN THIS FILE IF YOU CAN HELP IT.
* WE ARE TRYING TO DEPRECATE THIS ALL IN FAVOR OF HOMEFIELD THEMING AND SEMANTIC COLORING.
* PLEASE REFER TO HOMEFIELD.DCSG.COM FOR MORE INFO
*
*
*
*
*
*
*
*/


// Theme color palette chain
$theme-color-chain: 'dsg';

/**
 * DSG UI COLOR PALETTE
 */


// Blue
$color-dsg-blue-50: #e2e6ea;
$color-dsg-blue-400: #335069;
$color-dsg-blue-500: #0f314f;
$color-dsg-blue-600: #0d2c48;
$color-dsg-blue-700: #0b253f;
$color-dsg-blue-800: #081f36;
$color-dsg-blue-900: #041326;


// Blue Alias
$color-blue-500:$color-dsg-blue-500;

// Orange
$color-dsg-orange-50: #fef3e5;
$color-dsg-orange-100: #fde0bf;
$color-dsg-orange-200: #fccb95;
$color-dsg-orange-300: #fab66a;
$color-dsg-orange-400: #f9a74a;
$color-dsg-orange-500: #f8972a;
$color-dsg-orange-600: #f78f25;
$color-dsg-orange-700: #f6841f;
$color-dsg-orange-800: #f57a19;
$color-dsg-orange-900: #f3690f;
// Dark Orange
$color-dsg-orange-dark-50: #f7ebe2;
$color-dsg-orange-dark-100: #ebcdb8;
$color-dsg-orange-dark-200: #ddac88;
$color-dsg-orange-dark-300: #cf8a58;
$color-dsg-orange-dark-400: #c57135;
$color-dsg-orange-dark-500: #bb5811;
$color-dsg-orange-dark-600: #b5500f;
$color-dsg-orange-dark-700: #ac470c;
$color-dsg-orange-dark-800: #a43d0a;
$color-dsg-orange-dark-900: #962d05;

/**
 * DSG UI COLOR ALIASES
 */

// DSG GREEN
$theme-primary-color: var(--hmf-scgreen);
$primary-font-color: var(--hmf-gray900);
$primary-link-color: var(--hmf-scgreen);
$color-link-underline: var(--hmf-gray900);
$emphasized-details-text-color: var(--hmf-gray900);
$edd-color: ds-getColor('green', 'base', '600', $theme-color-chain);

$color-dsg-standard-orange: #ed8b26;
$color-dsg-standard-orange-2: #d56c27;
$color-dsg-standard-orange-hover: #ff7f00;
$color-dsg-standard-orange-active: #ff7f00;
$color-dsg-light-orange: #eab583;
$color-dsg-gold: #ed8b26;
$color-dsg-light-orange-2: #f9c490;
$color-dsg-light-orange-transparency75: rgba(234, 181, 131, 0.75);
$color-dsg-light-orange-transparency50: rgba(234, 181, 131, 0.5);
$color-dsg-light-orange-transparency25: rgba(234, 181, 131, 0.25);
$color-dsg-orange-dark: #bb5811;
$color-dsg-orange-dark-2: #b85c00;
$color-dsg-orange-burnt: #af5700;
$color-dsg-orange-gradient-top: #f68a21;
$color-dsg-orange-gradient-bottom: #c56c27;
$color-dsg-green-dark: #00483a;
$color-dsg-blue-light: #aee0f8;
$color-dsg-gray-light: #f1f1f1;
$color-dsg-gray-light-2: #f2f2f2;
$color-dsg-gray-light-3: #e6e6e6;
$color-dsg-gray-light-4: #dedede;
$color-dsg-standard-gray: #c5c5c5;
$color-dsg-standard-gray-2: #c6c5c4;
$color-dsg-standard-gray-3: #c6c6c6;
$color-dsg-gray-medium: #a1a1a1;
$color-dsg-gray-medium-2: #9f9f9f;
$color-dsg-gray-dark-2: #999999;
$color-dsg-gray-dark-3: #707070;
$color-dsg-gray-dark-4: #555;
$color-dsg-gray-gradient-top: #e6e6e6;
$color-dsg-gray-gradient-bottom: #c6c5c4;
$color-dsg-black-2: #666666;
$color-dsg-footer-bg: #f9f9f9;
$color-dsg-footer-red: #b90930;
$color-dsg-signup-bg: #fff;

$color-dsg-orange-dark: #bb5811;
$color-dsg-orang-dark-400: #c57135;


$button-primary-background: $color-dsg-orange-dark-500;
$button-primary-background-hover: $color-dsg-orange-dark-700;
$button-primary-background-active: $color-dsg-orange-dark-400;
$button-primary-inactive-bg: var(--hmf-gray300);
$button-primary-inactive-text: var(--hmf-gray500);

/**
 * DSG UI COLOR ALIASES
 */
// DSG GREEN
$theme-primary-color: var(--hmf-scgreen);
$primary-font-color: var(--hmf-gray900);
$primary-link-color: var(--hmf-scgreen);
$dsg-error-red: $color-red;
$site-background: var(--hmf-gray50);
$canvas-mask: var(--hmf-gray900);
$color-browser-highlight: $color-dsg-blue-light;
$star-ratings: $color-dsg-gold;
$swatch-border: $color-dsg-gray-medium;
$swatch-selected-border: $color-dsg-deep-orange;

$check-circle-color: var(--hmf-scgreen);
$error-circle-color: var(--hmf-red600);
$low-inventory-color: #910208;

$error-message-red: $dsg-error-red;
$modal-header-background: $color-dsg-gray-light-3;
$ripple-color: $color-dsg-orange-dark;
$btn-atc-hover: $color-dsg-standard-orange-hover;
$swatch-selected-border-transparency: $color-dsg-light-orange-transparency75;

$pdp-header-color: var(--hmf-gray900);
$promo-details-h2: var(--hmf-scgreen);
$inline-text-primary-color: var(--hmf-gray900);
$inline-text-hover-color: $color-dsg-orange-dark-500;
$inline-text-active-color: var(--hmf-gray600);
$inline-text-visited-color: var(--hmf-gray600);

// STATIC LINKS
$static-link-text-color: var(--hmf-gray900);
$static-link-underline-color: var(--hmf-gray900);
$static-link-hover-text-color: var(--hmf-gray900);
$static-link-hover-underline-color: $color-dsg-orange-dark-500;
$static-link-active-text-color: var(--hmf-gray600);
$static-link-active-underline-color: $color-dsg-orange-dark-400;
$static-link-focus-text-color: var(--hmf-gray900);
$static-link-focus-underline-color: $color-dsg-orange-dark-500;
$static-link-visited-text-color: var(--hmf-gray600);
$static-link-visited-underline-color: var(--hmf-gray600);
// STATIC TEXT LINKS -- this should be set at a global level eventually
@mixin static-text-link {
	@include body-bold-sm;
	color: $static-link-text-color;
	cursor: pointer;
	text-decoration: underline;
	text-decoration-color: $static-link-underline-color;
	&:visited {
		color: $static-link-visited-text-color;
		text-decoration: underline;
		text-decoration-color: $static-link-visited-underline-color;
	}
	&:hover {
		color: $static-link-hover-text-color;
		text-decoration: underline;
		text-decoration-color: $static-link-hover-underline-color;
	}
	&:active {
		color: $static-link-active-text-color;
		text-decoration: underline;
		text-decoration-color: $static-link-active-underline-color;
	}
}

/* low-stock */
$low-stock-message: var(--hmf-red600);

/**
 * COMPONENTS
 */
$btn-border-radius: 0.25rem;
$btn-background-primary: $color-dsg-orange-dark;
$btn-background-primary-hover: $color-dsg-standard-orange-hover;
$btn-background-primary-active: $color-dsg-standard-orange-active;
$btn-background-secondary: $color-dsg-gray-light-3;
$btn-color-in-cart: $color-dsg-deep-orange;
$btn-border-primary: 1px solid $color-dsg-orange-dark;
$btn-border-primary-hover: $color-dsg-standard-orange-hover;
$btn-border-primary-active: $color-dsg-standard-orange-active;
$btn-border-secondary: 1px solid $color-dsg-standard-gray-2;
$btn-font-color-secondary: $color-dsg-gray-dark-4;
$btn-gradient-top-primary: $color-dsg-orange-gradient-top;
$btn-gradient-bottom-primary: $color-dsg-orange-gradient-bottom;
$btn-gradient-top-secondary: $color-dsg-gray-gradient-top;
$btn-gradient-bottom-secondary: $color-dsg-gray-gradient-bottom;
$nav-menu-header-border-bottom: $color-dsg-orange-dark;
$mini-cart-count-background-color: $color-dsg-orange-dark-2;
$hyperlink-color-primary: var(--hmf-gray500);
$hyperlink-color-primary-hover: $color-dsg-orange-dark;
$hyperlink-color-primary-active: $color-dsg-orang-dark-400;
$hyperlink-color-primary-visited: var(--hmf-gray500);
$hyperlink-color-primary-foucs: $color-dsg-orange-dark;
$ticker-background: var(--hmf-scgreen);
$ticker-color: var(--hmf-gray50);
$progress-spinner-stroke-color-primary: $color-dsg-orange-dark;
$footer-banner-display: block;
$email-signup-bg: $color-dsg-signup-bg;
$snackbar-border-top: 3px solid $color-dsg-orange-dark;
$scorecard-reward-color: var(--hmf-scgreen);

$text-accent-color: #BB5811;

$certona-background: #333333;
$atc-modal-heading-font: 'DSG Sans Black';
$atc-modal-heading-font-size: 21px;
$atc-modal-heading-line-height: 20px;
$atc-modal-heading-letter-spacing: 0.95px;

//BreadCrumb Links
$breadcrumb-link-hover: var(--hmf-scgreen);
$breadcrumb-link-active: $color-dsg-green-dark;
$breadcrumb-link-visited: $color-dsg-black-2;

/**
 * DSG TYPOGRAPHY
 */
//Font sizing
$font-size-sm: 1.3rem;

//Font Family
$font-family-primary: 'Archivo', sans-serif;
$font-family-secondary: 'Archivo', sans-serif;
$font-family-tertiary: 'Archivo', sans-serif;
$font-family-buttons: $font-family-tertiary;
$font-color-primary: var(--hmf-gray900);

//Bopis font family
$bopis-font-family: $font-family-primary;

/**
 * DSG UTILITIES
 */

$heading-font: $font-family-primary;
$heading-letter-spacing: 1px;
$heading-font-size: $font-size-md-3;

$subheading-font: $font-family-primary;

//Category Fonts
// Badge Colors
$badge-foreground-default: var(--hmf-gray50);
$badge-background-default: var(--hmf-scgreen);

$badge-font-family: 'DSG Sans Bold';
$heading-font-2: 'DSG Sans Black';
$heading-letter-spacing: 1px;
$heading-font-size: $font-size-md-3;
$h1-font: $heading-font;
$cta-mat-icon: '\E5C8';
$cta-icon-font-size: 100%;
$headline-font-size: 4vw;

// category colors
$category-tab-left-highlight: $color-dsg-standard-orange;
$category-tab-chevron: rgba(var(--hmf-gray900-rgb), 0.87);
$category-tab-internal-text: rgba(var(--hmf-gray900-rgb), 0.87);
$footer-background-color: white;

$deals-background: $color-dsg-gray-light;

//BreadCrumb Links
$breadcrumb-link-hover: var(--hmf-scgreen);
$breadcrumb-link-active: $color-dsg-green-dark;
$breadcrumb-link-visited: $color-dsg-black-2;
$breadcrumb-color-link: var(--hmf-gray900);
// Cta
$cta-text-color: var(--hmf-gray900);
// FullStory Component
$full-story-copy-block-color: rgba(var(--hmf-gray50-rgb), 0.9);
$full-story-copy-block-text-color: var(--hmf-gray900);

//Home Fonts
//Home Colors
$disruptor-font-color: var(--hmf-gray50);
$disruptor-countdown-color:  var(--hmf-gray50);
$disruptor-countdown-bkground-color: var(--hmf-gray900);

/*
Deal Badges
*/
// colors
$deal-badge-going-fast: var(--hmf-red500);
$deal-badge-new: var(--hmf-primary400);
$deal-badge-while-supplies-last: $color-dsg-orange-dark-400;
$deal-badge-background-color: var(--hmf-red600);

// Logo Dimension
$logo-width: 75px;
$logo-height: 37px;
$logo-height-large: 50px;
$logo-margin-top: -2px;

$cart-color: var(--hmf-gray50);

// scene7 zoom loader image
$loader-image: 'assets/image/dsgspinner.svg';

$footer-background-color: white;

// Footer v2
$footer-banner-background: var(--hmf-gray900);
$footer-text-color: var(--hmf-gray900);
$footer-default-font: 'Archivo', Arial, sans-serif;
$footer-heading-font1: 'Archivo', Arial, sans-serif;
$footer-heading-font2: 'DSG Sans Black', Arial, sans-serif;
$border-banner: #e3e4e4;
$footer-heading-font-weight: 700;
$footer-heading-font-weight2: 700;
$footer-signup-btn-color: #bb5811;
$footer-signup-btn-hover: #ac470c;
$footer-signup-btn-active: #c57135;
$footer-signup-btn-focus: #bb5811;
$footer-link-hover: #bb5811;
$footer-link-active: #bb5811;
$footer-link-visited: #5e6260;
$footer-column-header-spacing: 0.5px;
$footer-signup-btn-text-color: var(--hmf-gray50);

// Search
$color-search-hover: $footer-signup-btn-color;

/**
 * TYPOGRAPHY
 */
$display-font: 'DSG Sans Black';
$primary-font: 'Archivo';


// in-use aliases
$primary-font-family: $primary-font;
$font-family-primary: $primary-font-family;

// Font Sizing
$fsize-10: 1rem;
$fsize-11: 1.1rem;
$fsize-12: 1.2rem;
$fsize-13: 1.3rem;
$fsize-14: 1.4rem;
$fsize-16: 1.6rem;
$fsize-20: 2rem;
$fsize-24: 2.4rem;
$fsize-32: 3.2rem;
$fsize-48: 4.8rem;
$fsize-64: 6.4rem;
$fsize-100: 10rem;


// Font Weights
$display-font-regular: 400;
$display-font-bold: 700;
$primary-font-regular: 400;
$primary-font-medium: 700;
$primary-font-bold: 700;
$font-regular: $primary-font-regular;
$font-bold: $primary-font-bold;
$font-medium: $primary-font-bold;

// Letter Spacing
$letter-spacing-xs: 0.5px;
$letter-spacing-sm: 1.5px;
$letter-spacing-md: 2px;
$letter-spacing-lg: 4px;
// Line Heights
$lh-10: 1rem;
$lh-12: 1.2rem;
$lh-14: 1.4rem;
$lh-16: 1.6rem;
$lh-18: 1.8rem;
$lh-20: 2rem;
$lh-24: 2.4rem;
$lh-28: 2.8rem;
$lh-34: 3.4rem;
$lh-36: 3.6rem;
$lh-50: 5rem;
$lh-68: 6.8rem;
$lh-104: 10.4rem;

@mixin font-presets-display($size, $height, $ls) {
	font-family: $display-font, sans-serif;
	font-size: $size;
	letter-spacing: $ls;
	line-height: $height;
}

@mixin font-presets($weight, $size, $height, $ls) {
	font-family: $primary-font, sans-serif;
	font-size: $size;
	font-weight: $weight;
	letter-spacing: $ls;
	line-height: $height;
}

// DISPLAY FONTS
@mixin display-xl {
	@include font-presets-display($fsize-100, $lh-104, $letter-spacing-sm);
}

@mixin display-lg {
	@include font-presets-display($fsize-64, $lh-68, $letter-spacing-md);
}
@mixin display-md {
	@include font-presets-display($fsize-48, $lh-50, $letter-spacing-md);
}
@mixin display-sm {
	@include font-presets-display($fsize-32, $lh-34, $letter-spacing-sm);
}
@mixin display-xs {
	@include font-presets-display($fsize-24, $lh-28, $letter-spacing-sm);
}

// HEADERS
@mixin heading-xl {
	@include font-presets($font-bold, $fsize-32, $lh-36, $letter-spacing-xs);
}
@mixin heading-lg {
	@include font-presets($font-bold, $fsize-24, $lh-28, $letter-spacing-xs);
}
@mixin heading-md {
	@include font-presets($font-bold, $fsize-20, $lh-24, $letter-spacing-xs);
}
@mixin heading-sm {
	@include font-presets($font-bold, $fsize-16, $lh-20, $letter-spacing-xs);
}
@mixin heading-xs {
	@include font-presets($font-bold, $fsize-14, $lh-18, $letter-spacing-xs);
}
@mixin heading-pdp {
	@include font-presets($font-bold, $fsize-24, $lh-28, $letter-spacing-xs);
}

// SUBHEADERS
@mixin subhead-reg-lg {
	@include font-presets($font-regular, $fsize-16, $lh-20, $letter-spacing-xs);
}
@mixin subhead-reg-md {
	@include font-presets($font-regular, $fsize-14, $lh-18, $letter-spacing-xs);
}
@mixin subhead-reg-sm {
	@include font-presets($font-regular, $fsize-12, $lh-16, $letter-spacing-xs);
}
@mixin subhead-bold-lg {
	@include font-presets($font-bold, $fsize-16, $lh-20, $letter-spacing-xs);
}
@mixin subhead-bold-md {
	@include font-presets($font-bold, $fsize-14, $lh-18, $letter-spacing-xs);
}
@mixin subhead-bold-sm {
	@include font-presets($font-bold, $fsize-12, $lh-16, $letter-spacing-xs);
}

// labels
@mixin label-lg {
	@include font-presets($font-bold, $fsize-16, $lh-16, $letter-spacing-sm);
}
@mixin label-md {
	@include font-presets($font-bold, $fsize-14, $lh-14, $letter-spacing-sm);
}
@mixin label-sm {
	@include font-presets($font-bold, $fsize-12, $lh-12, $letter-spacing-sm);
}
@mixin label-xs {
	@include font-presets($font-bold, $fsize-10, $lh-10, $letter-spacing-sm);
}
@mixin label-lg-chk {
	@include label-lg();
}

// BODY
@mixin body-reg-lg {
	@include font-presets($font-regular, $fsize-16, $lh-24, $letter-spacing-xs);
}
@mixin body-reg-md {
	@include font-presets($font-regular, $fsize-14, $lh-24, $letter-spacing-xs);
}
@mixin body-reg-sm {
	@include font-presets($font-regular, $fsize-12, $lh-20, $letter-spacing-xs);
}
@mixin body-reg-xs {
	@include font-presets($font-regular, $fsize-10, $lh-16, $letter-spacing-xs);
}
@mixin body-bold-lg {
	@include font-presets($font-bold, $fsize-16, $lh-24, $letter-spacing-xs);
}
@mixin body-bold-md {
	@include font-presets($font-bold, $fsize-14, $lh-24, $letter-spacing-xs);
}
@mixin body-bold-sm {
	@include font-presets($font-bold, $fsize-12, $lh-20, $letter-spacing-xs);
}
@mixin body-bold-xs {
	@include font-presets($font-bold, $fsize-10, $lh-16, $letter-spacing-xs);
}
@mixin heading-product-description {
	@include heading-md();
}

// INLINE LINKS
@mixin inline-text-md {
	@include font-presets($font-regular, $fsize-14, $lh-24, $letter-spacing-xs);
	color: $inline-text-primary-color;
	text-decoration: underline;
	&:hover {
		color: $inline-text-hover-color;
		font-weight: $font-bold;
	}
	&:active {
		color: $inline-text-active-color;
		font-weight: $font-bold;
	}
	&:focus {
		color: $inline-text-primary-color;
		font-weight: $font-bold;
	}
	&:visited {
		color: $inline-text-visited-color;
	}
}
@mixin inline-text-sm {
	@include font-presets($font-regular, $fsize-12, $lh-20, $letter-spacing-xs);
	font-weight: $font-bold;
	text-decoration: underline;
	&:hover {
		color: $inline-text-hover-color;
		font-weight: $font-bold;
	}
	&:active {
		color: $inline-text-active-color;
		font-weight: $font-bold;
	}
	&:focus {
		color: $inline-text-hover-color;
		font-weight: $font-bold;
	}
	&:visited {
		color: $inline-text-visited-color;
	}
}
@mixin inline-text-xs {
	@include font-presets($font-regular, $fsize-11, $lh-16, $letter-spacing-xs);
	font-weight: $font-bold;
	text-decoration: underline;
	&:hover {
		color: $inline-text-hover-color;
		font-weight: $font-bold;
	}
	&:active {
		color: $inline-text-active-color;
		font-weight: $font-bold;
	}
	&:focus {
		color: $inline-text-hover-color;
		font-weight: $font-bold;
	}
	&:visited {
		color: $inline-text-visited-color;
	}
}

// LEGAL
@mixin legal-text-sm {
	@include font-presets($font-regular, $fsize-12, $lh-18, $letter-spacing-xs);
}
@mixin legal-text-xs {
	@include font-presets($font-regular, $fsize-11, $lh-16, $letter-spacing-xs);
}

/**
 * PDP ALIASES
 * Prefix pdp-specific vars with pdp- to avoid collisions.
 * Variable names NEED to be the same in each _vars.*.scss file in order to work cross-store
 */

/* FOCUS */
$attribute-focus-dashed: ds-getColor('green', 'base', '500', $theme-color-chain);

/* SNACKBAR */
$snackbar-heading-font: $display-font;
$snackbar-heading-letter-spacing: $letter-spacing-sm;

/* BREADCRUMBS */
$breadcrumb-text-color: var(--hmf-gray600);

/* RATINGS AND REVIEWS */
$ratings-reviews-stars: $color-dsg-orange-500;

/* PRODUCT TITLE */
$pdp-heading-color: var(--hmf-gray900); // can we alias this value as well?

/* PRICING */
$pricing-bg-color-standard: var(--hmf-gray900);
$pricing-bg-color-secondary: var(--hmf-red600);

/* PROMOTION */
$pdp-promotion-text-color: var(--hmf-red600);
$panel-header-background-color: var(--hmf-gray100);
$panel-header-border-color: var(--hmf-gray200);
@mixin pdp-promo-details-text {
	@include font-presets($font-bold, $fsize-13, $lh-18, $letter-spacing-xs);
}

/* homR-text-link */
@mixin pdp-homr-text-link {
	@include font-presets($font-regular, $fsize-14, $lh-18, $letter-spacing-xs);
}

/* ATTRIBUTES */
$swatch-selected-border-transparency: rgba($color-dsg-orange-100, 1);

// Round Swatches
$color-swatch-border: var(--hmf-gray400);
$color-round-swatch-selected-border: var(--hmf-gray700);

// Block Attributes
$block-attribute-background-color: var(--hmf-gray100);
$block-selected-background-color: var(--hmf-gray50);

$block-swatch-border: var(--hmf-gray500);

$block-attribute-selected-border: var(--hmf-gray600);
$selected-block-button-bottom: $color-dsg-orange-dark-500;
$selected-block-button-top: var(--hmf-gray50);

$block-swatch-hover-background: var(--hmf-gray50);
$color-attribute-text-standard: var(--hmf-gray900);
$color-attribute-group-divider: var(--hmf-gray300);
$color-block-swatch-hover: var(--hmf-gray400);
$color-round-swatch-selected-border: var(--hmf-gray600);
$color-round-swatch-hover-bg: var(--hmf-gray200);
$color-round-swatch-hover-border: var(--hmf-gray600);
$color-round-swatch-strikethrough: var(--hmf-gray600);
$block-disabled-text-color: var(--hmf-gray800);

@mixin pdp-attribute-identifier {
	@include font-presets($font-medium, $fsize-14, $lh-18, $letter-spacing-sm);
	text-transform: uppercase;
}
@mixin block-swatch-text {
	@include body-reg-md;
}
@mixin size-chart-text{
	font-weight: 700;
}
@mixin block-focus-text{
	&:focus {
		outline: 1px dashed $attribute-focus-dashed;
		outline-offset: 5px;
	}
}
@mixin block-attr-swatch-content{
	background-color: $block-attribute-background-color;
	border: 1px solid $block-swatch-border;
	color: $color-attribute-text-standard;
	&:hover {
		background-color: $block-swatch-hover-background;
		border-color: $color-block-swatch-hover;
		transition: background-color $transition-duration-xshort;
	}
	&:focus {
		outline: 1px dashed $attribute-focus-dashed;
		outline-offset: 5px;
	}
}
@mixin selected-pdp-block-button {
	border: 1px solid $block-attribute-selected-border;
	// Using 4 color stops for Microsoft Compatibility
	background: linear-gradient(to top, $selected-block-button-bottom, $selected-block-button-bottom 4px, var(--hmf-gray50) 4px, var(--hmf-gray50) 100%);
	font-weight: $font-bold;
}

@mixin disabled-block-swatch {
	background: repeating-linear-gradient(152deg, var(--hmf-gray300), var(--hmf-gray300) 1px, var(--hmf-gray100) 1px, var(--hmf-gray100) 10px);
	border: 1px solid var(--hmf-gray300);
	color: var(--hmf-gray600);
}

@mixin disabled-block-swatch-with-underline {
	background:
	linear-gradient(to top, $selected-block-button-bottom, $selected-block-button-bottom 4px, transparent 4px, transparent 100%),
	repeating-linear-gradient(152deg, var(--hmf-gray300), var(--hmf-gray300) 1px, var(--hmf-gray100) 1px, var(--hmf-gray100) 10px);
	border: 1px solid $block-attribute-selected-border;
}
@mixin block-attr-swatch-content-new{
		background-color: $block-attribute-background-color;
		border: 1px solid $block-swatch-border;
		color: $color-attribute-text-standard;
		&:focus {
			outline: 1px dashed $attribute-focus-dashed;
			outline-offset: 5px;
		}
		&:hover {
			background-color: $block-swatch-hover-background;
			border-color: $color-block-swatch-hover;
			transition: background-color $transition-duration-xshort;
		}
}
@mixin selected-pdp-block-button-new {
	border: 1px solid $block-attribute-selected-border;
	// Using 4 color stops for Microsoft Compatibility
	background: linear-gradient(to top, $selected-block-button-bottom, $selected-block-button-bottom 4px, var(--hmf-gray50) 4px, var(--hmf-gray50) 100%);
	font-weight: $font-bold;
}

@mixin disabled-block-swatch-new {
	background: repeating-linear-gradient(152deg, var(--hmf-gray300), var(--hmf-gray300) 1px, var(--hmf-gray100) 1px, var(--hmf-gray100) 10px);
	border: 1px solid var(--hmf-gray300);
	color: var(--hmf-gray400)-xdark;
}

@mixin disabled-block-swatch-with-underline-new {
	background:
	linear-gradient(to top, $selected-block-button-bottom, $selected-block-button-bottom 4px, transparent 4px, transparent 100%),
	repeating-linear-gradient(152deg, var(--hmf-gray300), var(--hmf-gray300) 1px, var(--hmf-gray100) 1px, var(--hmf-gray100) 10px);
	border: 1px solid $block-attribute-selected-border;
}


/* PRODUCT DESCRIPTION */
$color-prod-desc-copy: var(--hmf-gray400)-xdark;
$color-panel-hr: $color-dsg-orange-dark-500;
@mixin product-info-h3 {
	@include heading-xs;
	text-transform: uppercase;
	color: var(--hmf-gray400)-xdark;
}

/* ACCORIDIONS */
$color-accordion-hr: var(--hmf-secondary500);

/* ADD TO CART */
/* LABELS */
@mixin atc-button-label {
	@include label-md;
}

@mixin shipping-label {
	@include font-presets($atc-label-bold, $fsize-14, $lh-24, $letter-spacing-xs);
}

$atc-label-bold: $font-bold;

$atc-button: $color-dsg-orange-dark-500;

$atc-input-border-color: var(--hmf-gray600);

$atc-hover: $color-dsg-orange-dark-700;
$atc-focus: $color-dsg-orange-600;
$atc-inactive: $color-dsg-orange-600;
$atc-inactive-background-color: var(--hmf-gray300);
$atc-inactive-button-text-color: var(--hmf-gray500);

$attribute-error-color: #c50c21;

// Success Icons
$success-icon-color-color: 'green';
$success-icon-color-level: 'base';
$success-icon-color-value: '500';

/* ATC Radios */
$atc-radio-border-dimmed: var(--hmf-gray500);
$atc-radio-border-active: var(--hmf-gray900);
$atc-radio-dot: var(--hmf-gray900);

/* CARD */
// Box shadow
$dcsg-card-box-shadow-color-color: 'gray';
$dcsg-card-box-shadow-color-level: 'base';
$dcsg-card-box-shadow-color-value: '400';

/* PROMOTIONS */
$header-icon-svg-filter: brightness(0) saturate(100%) invert(37%) sepia(27%) saturate(2390%) hue-rotate(353deg) brightness(98%) contrast(94%);

@mixin promo-panel-h1 {
	@include font-presets(700, $fsize-16, $lh-20, $letter-spacing-xs);
}

//Pdp bundle page
$item-options-color: ds-getColor('green', 'base', '300', $theme-color-chain);
$create-your-bundle-background: ds-getColor('brown', 'base', '200', $theme-color-chain);
$item-display-count-color: ds-getColor('green', 'base', '500', $theme-color-chain);
$add-to-bundle-color: ds-getColor('blue', 'base', '500', $theme-color-chain);
$bundle-secondary-font-family: $primary-font-family;
$title-divider-color: $color-attribute-group-divider;
$item-divider-color: ds-getColor('green', 'base', '400', $theme-color-chain);

/* ERROR MESSAGES */
$low-stock-ship-error-text-color: 'green';
$low-stock-ship-error-text-level: 'base';
$low-stock-ship-error-text-value: '500';
$low-stock-ship-radio-error-color: var(--hmf-red500);

/* SVGs */
// Error color for SVGs
$svg-filter-error-icon-color: invert(33%) sepia(36%) saturate(6670%) hue-rotate(337deg) brightness(67%) contrast(128%);

// Info color for SVGs
$svg-filter-info-icon-color: invert(12%) sepia(86%) saturate(867%) hue-rotate(177deg) brightness(99%) contrast(94%);

// Warn color for SVGs
$svg-filter-warn-icon-color: invert(71%) sepia(87%) saturate(714%) hue-rotate(350deg) brightness(102%) contrast(101%);

// Success color for SVGs
$svg-filter-success-icon-color: invert(37%) sepia(23%) saturate(2502%) hue-rotate(130deg) brightness(101%) contrast(102%);

//Site Down background image
$site-down-background-image: 'https://dks.scene7.com/is/image/dkscdn/DSG_SiteDown';

/*  PDP ATC NEW UI */
/* these values will need updated per brand */
$color-green-atc-v3-solid: #008D75;
$color-green-atc-v3-hi: rgba(224, 241, 238, 0.3);
$color-yellow-atc-v3-hi: rgba(255, 237, 182, 0.3);
$color-red-atc-v3-solid: var(--hmf-red800);
$color-red-atc-v3-hi: rgba(246, 225, 227, 0.25);

$color-atc-header-underline: ds-getColor('orange', 'dark', '500', $theme-color-chain);

//pdp fullfillent button styling
$fullfillment-button-outline-color: var(--hmf-gray400);
$fullfillment-button-background-color: var(--hmf-gray200);
$inactive-fullfillent-radio-color: var(--hmf-gray400);
$inactive-fullfillent-radio-background-color: var(--hmf-gray50);
$inline-store-select-color: var(--hmf-gray900);

//pdp color swatch styling
$color-swatch-border-radius: 50%;
$color-swatch-padding: $quarter-space;
$disabled-color-swatch-border: $color-swatch-border;

//other pdp styling
$feedback-color: var(--hmf-gray900);
$title-and-link-font-weight: $font-bold;
$title-and-link-font-size: $font-size-xs-3;
$personalization-text-field-disabled: #EAEBEB;
$personalization-img-color: var(--hmf-gray900);
$customazation-summary-color: var(--hmf-gray900);
$customization-border-color: var(--hmf-gray300);

//store layer colors
$available-store-count-background: var(--hmf-gray100);
$available-store-count-color: $color-gray-dark-6;
$sku-availability-check-circle-color: $color-green-light;
$sku-availability-cancel-color: var(--hmf-label-negative);

/* MYACCOUNT NEW UI */
$color-goldscorecard: #C0AD78;
$color-progress-bar: #BABCBB;

//Material Input CSS
$inactive-border: 1px solid #e3e4e4;
$focused-border: 1px solid var(--hmf-gray900);
$visitied-border: 1px solid #babcbb;
$hover-border: 1px solid #babcbb;
$disabled-border: 1px solid #e3e4e4;

$inactive-mat-text: #5e6260;
$focused-mat-text: var(--hmf-gray900);
$visitied-text: #babcbb;
$hover-text: #babcbb;
$disabled-text: #5e6260;

// Aem colors
$aem-read-more: $primary-font-color;
$aem-heading: $primary-font-color;

// Home Step Colors
$step-orange-fill-color: $color-dsg-orange-900;
$step-blue-fill-color: $color-dsg-blue-500;
$step-green-fill-color: $color-dsg-green-dark;


// Store Layer Styles - created 5.16.22 with QV update
$store-layer-header-icon: $color-dsg-orange-dark-700;

// Stylitics Galleries - Proper colors not provided by design
$stylitics-horizontal-rule: $primary-font-color;
$stylitics-cta-hover-underline: $primary-font-color;
$stylitics-carousel-chevron-hover-color: $primary-font-color;
$stylitics-close-button-hover-color: $primary-font-color;

//simple account create header
$simple-header-background-color: $primary-font-color;

// simple account create footer
$footer-bottom-group-background: var(--hmf-gray200);
