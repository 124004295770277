/** @format */
@use 'sass:map';

$tokens-colors: (
	'red': (
		'base': (
			'50': #f6e1e3,
			'100': #eab5ba,
			'200': #dc848c,
			'300': #cd525d,
			'400': #c32d3b,
			'500': #b80818,
			'600': #b10715,
			'700': #a80611,
			'800': #a0040e,
			'900': #910208
		)
	),
	'yellow': (
		'base': (
			'50': #fff8e2,
			'100': #ffedb6,
			'200': #ffe286,
			'300': #ffd655,
			'400': #ffcd30,
			'500': #ffc40c,
			'600': #ffbe0a,
			'700': #ffb608,
			'800': #ffaf06,
			'900': #ffa203
		)
	),
	'gray': (
		'base': (
			'50': #ffffff,
			'100': #f9f9f9,
			'200': #ededed,
			'300': #e9e9e9,
			'400': #c8c8c8,
			'500': #a0a0a0,
			'600': #797979,
			'700': #4f4f4f,
			'800': #2e2e2e,
			'900': #191f1c
		)
	)
);
