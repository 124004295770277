/** @format */

@use '../../variables' as *;
@use 'node_modules/@dsg-tech/homefield-styles/src/lib/homefield-functions.scss' as homefield;

/*********** GENERIC STYLING ***********/
@mixin hmf-button-text {
	@include homefield.font('l', 'label');

	@include homefield.media-breakpoint-down('m') {
		@include homefield.font('m', 'label');
	}
}
