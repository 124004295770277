/** @format */
@use 'sass:map';
@use '../../utils/_' as utils;

$tokens-breakpoints: (
	xs: (
		min-screen: utils.ds-remCalc(map.get(utils.$homefield-breakpoints, 'xs'))
	),
	s: (
		min-screen: utils.ds-remCalc(map.get(utils.$homefield-breakpoints, 's'))
	),
	m: (
		min-screen: utils.ds-remCalc(map.get(utils.$homefield-breakpoints, 'm'))
	),
	l: (
		min-screen: utils.ds-remCalc(map.get(utils.$homefield-breakpoints, 'l'))
	),
	xl: (
		min-screen: utils.ds-remCalc(map.get(utils.$homefield-breakpoints, 'xl'))
	)
);
