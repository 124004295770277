/** @format */
/**
 * You can add global styles to this file, and also import other style files
 */


/*************** IMPORT STYLES ***************/
// ::ng-deep overrides
@use './styles-deep' as *;
@forward './styles-deep';
@use '@dsg-tech/homefield-styles/src/lib/homefield-functions.scss' as homefield;

body {
	background-color: var(--hmf-gray50);
}

body.mouse-Event:focus {
	outline: none;
}

/*
 * Custom Elements
 */
pdp-add-to-cart,
pdp-attribute-container,
pdp-attribute-label,
pdp-attributes,
pdp-block-attribute,
pdp-color-attribute,
pdp-custom-default,
pdp-customizer-summary,
pdp-out-of-stock-default,
pdp-personalization,
pdp-product-container,
pdp-product-customizer,
pdp-price,
pdp-product-image-viewer,
pdp-product-questions-and-answers,
pdp-product-recommendations,
pdp-product-review-summary,
pdp-product-reviews,
pdp-snackbar,
pdp-root {
	display: block;
}

@mixin dot-active-inactive($backgroundColor, $color) {
	width: 20px;
	height: 20px;
	background-color: var($backgroundColor);
	background-size: 20px 20px;
	border-radius: 50%;
	border: solid 1px var(--hmf-scgreen);
	text-align: center;
	color: var($color);
}

@mixin icon-style($color) {
	vertical-align: sub;
	font-size: 15px !important;
	color: $color;
	padding-top: 4px;
}

@mixin dot-start-finish($content, $marginLeft, $marginTop) {
	content: $content;
	background: transparent;
	font-size: 12px;
	margin-left: $marginLeft;
	display: block;
	width: 66px;
	margin-top: $marginTop;
	color: black;
}

@mixin product_club($fontFamily, $marginRight) {
	font-family: $fontFamily;

	img {
		margin-right: $marginRight;
	}
}

@mixin att-title($fontSize, $lineHeight) {
	font-weight: 500;
	font-size: $fontSize;
	line-height: $lineHeight;
}

.bg-light-grey {
	background-color: var(--hmf-gray100);
}

.margin-auto {
	margin: 0 auto;
}

/*
 * PDP Sections
 */
.launch-dialog-container {
	height: 90vh;
	width: 100vw;

	.mat-dialog-container {
		padding: 0;
	}
}

/*
 * BOPIS
 */
.selected {
	background-color: var(--hmf-gray100);
}

.dot {
	vertical-align: middle;
	height: 15px;
	width: 15px;
	background-color: var(--hmf-gray300);
	border-radius: 50%;
	display: inline-block;
}

.cancel-icon {
	@include icon-style($dsg-alert-error-icon-fill-color);
}

.confirm-icon {
	@include icon-style(ds-getColor($dsg-alert-success-icon-fill-color-color, $dsg-alert-success-icon-fill-color-level, $dsg-alert-success-icon-fill-color-value, $theme-color-chain));
}

.warning-icon {
	@include icon-style(ds-getColor($dsg-alert-warn-icon-standalone-fill-color-color, $dsg-alert-warn-icon-standalone-fill-color-level, $dsg-alert-warn-icon-standalone-fill-color-value, $theme-color-chain));
}

/*
 * Product Description
 */
.description-content {
	.product-component {
		p {
			&:last-child {
				@media screen and (max-width: 767px) {
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
				}
			}
		}
	}
}

.product-component {
	iframe {
		width: 100%;
	}
}

/*
 * Custom Products (MyCustomizer)
 */

.links {
	display: flex;
	justify-content: space-between;
	position: relative;
}

.dotActive {
	@include dot-active-inactive(--hmf-scgreen, --hmf-gray50)
}

.dotInactive {
	@include dot-active-inactive(--hmf-gray50, --hmf-gray900);
}

.dotStart::after {
	@include dot-start-finish('Get Started', -4px, 2px)
}

.dotFinish::after {
	@include dot-start-finish('Complete', -39px, 3px)
}

.wrap:before {
	content: '';
	position: absolute;
	margin-top: 10px;
	border-top: 1px solid #333;
	width: 95%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);

	@media (max-width: 540px) {
		width: 92%;
	}

	@media (max-width: 320px) {
		width: 90%;
	}
}

.customization-att-title {
	@include att-title(17px, 1);
}

.customization-att-name {
	@include att-title(12px, 1.2);
	text-indent: -9px;

	.material-icons.green-check {
		width: 10px;
	}
}

.customization-att-value {
	font-size: 12px;
	font-weight: 400;
}

.green-check {
	font-size: 14px !important;
	color: $color-green-light;
	padding-right: 3px;
	vertical-align: bottom;
}

.customization-image {
	width: 100%;
}

.edit-customization {
	width: 95%;
	text-decoration: underline;
	cursor: pointer;
	padding-bottom: 3px;
	border: none;
	background: transparent;
	margin-left: -6px;
	text-align: left;
}

.customise_product_club {
	@include product_club('Hind Siliguri', 9px)
}

.customise_product_club_dsg {
	@include product_club((Roboto, Helvetica, sans-serif), 9px)
}

.customization-element {
	.list-item-content {
		display: flex;
		flex-direction: row;
		align-items: center;
		box-sizing: border-box;
		padding: 0 16px;
		position: relative;
		height: inherit;
	}
}

.bv_rr {
	padding-bottom: $quarter-space;

	i {
		font-size: $font-size-md;
	}

	.bv_rating,
	.bv_count {
		font-size: $font-size-sm;
		padding-left: 0.5rem;
	}
}

.customization-border {
	@media screen and (max-width: 768px) {
		margin-top: -10px;
		margin-bottom: 25px;
	}
}

/*
 * Size Charts
 */
@media (max-width: 768px) {
	.scTable-wrapper {
		h3 {
			margin: 0 0 0.35714rem !important;
		}

		.scTable {
			border: 0 !important;

			.scTable-tr {
				margin: 0 0 1.42857rem !important;
				display: block !important;
				padding: 0 !important;

				// size chart start
				&.isHeadRow,
				&.isImgRow {
					display: none !important;
				}

				.scTable-td {
					-webkit-box-sizing: border-box !important;
					box-sizing: border-box !important;
					display: block !important;
					float: left !important;
					clear: left !important;
					width: 100% !important;
					text-align: right !important;
					font-size: 1.1em !important;
					border-bottom: 0.07143rem solid #a7a7a7 !important;
					border-right: 0.07143rem solid #a7a7a7 !important;
					border-left: 0.07143rem solid #a7a7a7 !important;

					&:first-child {
						background-color: #e6e6e6 !important;
						border-top: 0.07143rem solid #a7a7a7 !important;
					}

					&:before {
						content: attr(data-label) !important;
						float: left !important;
						font-weight: 700 !important;
						overflow-wrap: break-word !important;
						max-width: 50% !important;
						text-align: left !important;
					}

					&:after {
						content: '' !important;
						display: table !important;
						clear: both !important;
					}
				}
			}
		}

		.scp {
			padding: 1rem 1rem 2rem !important;

			.main-content {
				width: 100% !important;
			}
		}
	}
}

/*
 * BAZAAR VOICE OVERRIDE START
 */
.bv-mbox-opened .bv-mbox-lightbox .bv-mbox-box .bv-mbox {
	@media (max-width: 1024px) {
		overflow-y: scroll !important;
		height: 80vh !important;
		-webkit-overflow-scrolling: touch;
	}
}

.fade-in {
	animation: fadeIn ease 1s;
	-webkit-animation: fadeIn ease 1s;
	-moz-animation: fadeIn ease 1s;
	-o-animation: fadeIn ease 1s;
	-ms-animation: fadeIn ease 1s;
}

/*
 * BAZAAR VOICE OVERRIDE END
 */

/*
 * Certona Recommended Products
 */
.product-recommendation-card-container {
	.slick-arrow {
		opacity: 0.7;
		border-radius: 2px;
		background-color: var(--hmf-gray50);
	}

	.slick-prev,
	.slick-next {
		width: 44px !important;
		height: 44px !important;

		&:before {
			font-family: 'Material-Icons' !important;
			color: #a7a7a7 !important;
			font-size: 4.4rem !important;
		}
	}

	.slick-prev {
		left: -25px !important;

		&:before {
			content: 'keyboard_arrow_left' !important;
		}
	}

	.slick-next {
		margin-right: 3px !important;
		right: -35px !important;

		&:before {
			content: 'keyboard_arrow_right' !important;
		}
	}
}

// Adeptmind Scss Override
#product1_rr {
	.product-category-suggestions {
		@media screen and ($mobile) {
			overflow: hidden;
			-webkit-overflow-scrolling: touch;
			position: relative;
		}

		.product-recommendations-header {
			display: flex;
			justify-content: center;

			span {
				@include heading-md;
			}
		}

		ul {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;
			padding-bottom: var(--hmf-s);
			margin: 0;
			list-style: none;

			@media screen and ($mobile) {
				overflow-x: scroll;
				flex-wrap: inherit;
				justify-content: normal;
				padding-left: var(--hmf-xxs);
			}

			li {
				margin-right: var(--hmf-s);
				margin-top: var(--hmf-m);

				@media screen and ($mobile) {
					flex-shrink: 0;
				}

				a {
					box-shadow: 0 1px 3px rgba(var(--hmf-gray900-rgb), 0.25);
					border-radius: var(--hmf-round-radius);
					@include subhead-reg-lg;
					line-height: 1.7rem;
					padding: var(--hmf-xs) var(--hmf-s);
					display: inline-flex;
				}
			}
		}
	}
}

/*
 * Criteo Sponsored Products
 */
.sponsored-products-container {
	margin: 0 0 !important;
	display: block !important;

	.hl-product {
		background: var(--hmf-gray50) !important;
		margin-left: 10px !important;
		margin-right: 12px !important;
		padding: 10px !important;
		max-height: 220px !important;
		height: 220px !important;
	}

	.hl-productNames {
		font-size: 1.3rem !important;
		font-weight: 400 !important;
		height: 60px !important;
		width: 100% !important;

		a {
			color: var(--hmf-gray900);
			display: inline-block !important;

			:hover {
				text-decoration: underline;
				color: var(--hmf-scgreen);
			}
		}
	}

	.hl-product-price {
		color: #333333;
	}

	// slick styles to match with Certona
	.slick-arrow {
		opacity: 0.7;
		border-radius: 2px;
		background-color: var(--hmf-gray50);
	}

	.slick-next {
		margin-right: 5px !important;
		right: -20px !important;
	}

	.slick-prev {
		left: -15px !important;
	}

	.slick-prev,
	.slick-next {
		width: 44px !important;
		height: 44px !important;
	}

	.certona-stars {
		margin-top: 0 !important;
	}

	// IE overrides for slick arrow
	@media all and (-ms-high-contrast: none),
	(-ms-high-contrast: active) {
		.slick-dots {
			position: static !important;
		}
	}
}

/*************** PRODUCT COPY STYLES (MDM DESCRIPTION FIELD) -- START ***************/
/*
 * DSG Product Copy HTML Standards supporting CSS (new rules - 1/16/2015)
 */
.fb-button {
	font-size: 1.6rem !important;
	margin-bottom: 20px;
	padding: 12.5px 20px;
	font-weight: 400 !important;
}

/* Blockquote */
.product-component {
	margin-top: 1rem;

	p {
		margin-top: 3rem;
		padding-bottom: 0 !important;
	}

	figure {
		margin: 0;

		img {
			width: auto !important;
			max-width: 100% !important;
			height: auto !important;
			padding: 0.5rem;
			border: 0.2rem #ddd solid;
		}
	}

	iframe {
		display: block;
	}

	figcaption {
		margin-top: 0.5rem;
		font-size: 0.7692307692307692em;
		font-style: italic;
		line-height: 1.8rem;
	}

	q {
		&:before {
			content: '\201C';
		}

		&:after {
			content: '\201D';
		}
	}

	blockquote {
		padding: 1rem 2rem;
		margin: 0 0 2rem;
		font-size: 1.75rem;
		border-left: 0.5rem solid #ccc;

		cite {
			font-style: italic;
		}

		footer {
			display: block;
			font-size: 80%;
			line-height: 1.42857143;
			color: #777;

			&:before {
				content: '\2014 \00A0';
			}
		}
	}

	.product-header {
		font-size: 1.8rem;
		text-shadow: 0.1rem 0.01rem 0.01rem rgba(0, 0, 0, 0.1);
	}

	h1 {
		font-size: 1.8rem;
	}

	h2 {
		font-size: 1.6rem;
	}

	h3,
	h4,
	h5 {
		font-size: 1.4rem;
	}

	h6 {
		font-size: 1.2rem;
	}

	h3 {
		@include product-info-h3;
		margin: 3rem 0 2rem 0;
	}

	.product-footnote {
		font-size: 0.8461538461538462em;
	}

	ul,
	ol,
	dl {
		overflow: hidden;
	}
}

/* Medium width component (i.e. List) */
.product-component.medium-width {
	margin-right: 5% !important;
	margin-left: 5% !important;
}

/* Small width component (i.e. List) */
.product-component.small-width {
	margin-right: 10% !important;
	margin-left: 10% !important;
}

/* Extra-Small width component (i.e. List) */
.product-component.xsmall-width {
	margin-right: 15% !important;
	margin-left: 15% !important;
}

/* Product Attributes List */
.productDescription+ul {
	margin-top: 1.5rem;
}

.ratings-and-reviews-stars {
	.bv_rating {
		.material-icons.star {
			font-size: 15px !important;
		}

		.material-icons.star_border {
			font-size: 15px !important;
		}
	}
}

/*************** PRODUCT COPY STYLES (MDM DESCRIPTION FIELD) -- END ***************/
.mat-form-field-outline-start,
.mat-form-field-outline-end {
	border-radius: 0 !important;
}

.small-sidenav {
	.pdp-sidenav-show {
		width: 85%;

		@media (min-width: 992px) {
			width: 45% !important;
		}

		// Extra large devices (large desktops, 1200px and up)
		@media (min-width: 1200px) {
			width: 40% !important;
		}
	}
}

.description-text {
	line-height: 24px;
	letter-spacing: 0.5px;
	font-size: 14px;

	h3 {
		font-size: 14px;
	}
}

.footer-calia {
	#voc_d_pagefeedback {
		a {
			@media screen and (min-width: 768px) {
				color: $color-calia-light-pink-base;
				padding: 0 0 0 $half-space;
			}

			@media screen and (max-width: 767px) {
				width: 100%;
				display: inline-block;
			}

			color: $color-calia-purple-base;
			font-size: $font-size-xs-3;
			border: 0;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}

.marketing-content {
	.img-responsive {
		margin: 0 auto !important;
		padding: 15px;
	}
}

/*
 * Alert Message Component
 *
 */
.alert-message {
	margin: $six-tenths-space 0;
}

/* Product templates styles */
.flex-item {
	width: 100%;

	@include homefield.media-breakpoint-up('lg') {
		width: 50%;
	}
}

.pdp-content-width {
	max-width: 1036px;
	width: 100%;
}

.full-width {
	width: 100%;
}

.price-and-rating-stars {
	.rating-stars {
		flex-grow: 1;
	}
}

.pdt-custom {
	height: 100%;
}

.pla-template {
	width: 900px;
	height: 400px;
}

.pla-spinner {
	padding: 50px;
}

.pla-loading {
	opacity: 0;
}

.pla-content {
	opacity: 1;
}

.pla-body {
	visibility: visible;
	opacity: 1;
}

.panel-container {
	overflow: visible !important;

	.panel-description-content {
		padding: 100px 0 0 0;
	}
}

.border-top {
	border-top: 1px solid var(--hmf-gray100);
}

.border-top-none {
	border-top: none !important;
}

.sticky-image-container {
	@include media-breakpoint-up('l') {
		position: sticky;
		top: 0;
	}
}

.pdp-component-area {
	max-width: 1034px;
	margin: 0 auto;
	width: 100%;
}

.pl-product-info {
	margin-top: 20px;
}

@include homefield.media-breakpoint-up('lg') {
	#pdp-main-flex-container {
		padding: 0 var(--hmf-s);
	}

	.back-share-section {
		padding: var(--hmf-s) var(--hmf-s) 0 var(--hmf-s);
	}
}
