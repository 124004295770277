/**
 * /* You can add global styles to this file, and also import other style files
 *
 * @format
 */

/*************** IMPORT STYLES ***************/
// Chain Specific Variables
@import 'home-styles/chain';

/*
 * Custom Elements
 */
home-root {
	display: block;
}

.mat-form-field-outline-start,
.mat-form-field-outline-end {
	border-radius: 0 !important;
}
