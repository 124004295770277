/** @format */

// Font
$hp-title-font: 'DSG Sans Black', sans-serif;
$hp-font-color: $primary-font-color;
$description-text-color: var(--hmf-gray900);
$copybox-title-line-height: 1;
$heading-letter-spacing: 1px;

//Text Transforms
$section-header-text-transform: uppercase;
$subtext-text-transform: none;
$cta-text-transform: uppercase;
$title-text-transform: uppercase;

//HP Font Weight
$heading-font-weight: 500;
$hp-title-font-weight: 700;
$headline-font-weight: 700;

$badge-font-family: $display-font;

@mixin sectionHeaderFont() {
	font-family: $hp-title-font;
	color: $section-header-font-color;
	@include font-sizeByType('xs', 'display');
	@media ($desktop-md) {
		@include font-sizeByType('s', 'display');
	}
}

@mixin copyBoxHeaderFont() {
	@include display-md;
	@media ($desktop-md) {
		@include display-lg;
	}
}

@mixin titleHeaderFont() {
	@include font-sizeByType('xs', 'display');
	@media ($desktop-md) {
		@include font-sizeByType('s', 'display');
	}
}

@mixin subHeaderFont() {
	@include font-sizeByType('l', 'heading');
	@media ($desktop-md) {
		@include font-sizeByType('xl', 'heading');
	}
}

@mixin subTextFont() {
	@include font-sizeByType('m', 'label');
	@media ($desktop-md) {
		@include font-sizeByType('l', 'label');
	}
}

@mixin heroHeaderFont() {
	font-family: $hp-title-font;
	@include font-sizeByType('s', 'display');
	@media ($desktop-md) {
		@include font-sizeByType('m', 'display');
	}
}

@import 'text';
