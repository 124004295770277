/** @format */
@use '../../utils/_' as utils;

$tokens-textSizeBasePx: 10;

$tokens-textWeight: (
	// Note on thin fonts for future,
	// they don't show well on screens with lower-average pixel densities.
	// Check yourself before you wreck yourself.
	m: 400,
	// "Regular"
	l: 500,
	// "Medium"
	xl: 700,
	// "Semi-bold"
	xxl: 700,
	// "Bold"
);

$tokens-textTypeDisplay: (
	// general
	fontFamily: $font-display,
	fontWeight: $font-display-weight,
	// variants
	xs:
		(
			fontSize: utils.ds-remCalc($display-font-size-xs * 1px),
			lineHeight: utils.ds-remCalc($display-font-line-height-xs * 1px)
		),
	s: (
		fontSize: utils.ds-remCalc($display-font-size-s * 1px),
		lineHeight: utils.ds-remCalc($display-font-line-height-s * 1px)
	),
	m: (
		fontSize: utils.ds-remCalc($display-font-size-m * 1px),
		lineHeight: utils.ds-remCalc($display-font-line-height-m * 1px)
	),
	l: (
		fontSize: utils.ds-remCalc($display-font-size-l * 1px),
		lineHeight: utils.ds-remCalc($display-font-line-height-l * 1px)
	),
	xl: (
		fontSize: utils.ds-remCalc($display-font-size-xl * 1px),
		lineHeight: utils.ds-remCalc($display-font-line-height-xl * 1px)
	)
);

$tokens-textTypeHeading: (
	// general
	fontFamily: $font-primary,
	fontWeight: $font-primary-bold-weight,
	// variants
	xs:
		(
			fontSize: utils.ds-remCalc($heading-font-size-xs * 1px),
			lineHeight: utils.ds-remCalc($heading-font-line-height-xs * 1px)
		),
	s: (
		fontSize: utils.ds-remCalc($heading-font-size-s * 1px),
		lineHeight: utils.ds-remCalc($heading-font-line-height-s * 1px)
	),
	m: (
		fontSize: utils.ds-remCalc($heading-font-size-m * 1px),
		lineHeight: utils.ds-remCalc($heading-font-line-height-m * 1px)
	),
	l: (
		fontSize: utils.ds-remCalc($heading-font-size-l * 1px),
		lineHeight: utils.ds-remCalc($heading-font-line-height-l * 1px)
	),
	xl: (
		fontSize: utils.ds-remCalc($heading-font-size-xl * 1px),
		lineHeight: utils.ds-remCalc($heading-font-line-height-xl * 1px)
	)
);

$tokens-textTypeLabel: (
	// general
	fontFamily: $font-primary,
	fontWeight: $font-primary-bold-weight,
	letterSpacing: $label-letter-spacing,
	// variants
	xs:
		(
			fontSize: utils.ds-remCalc($label-font-size-height-xs * 1px),
			lineHeight: utils.ds-remCalc($label-font-size-height-xs * 1px)
		),
	s: (
		fontSize: utils.ds-remCalc($label-font-size-height-s * 1px),
		lineHeight: utils.ds-remCalc($label-font-size-height-s * 1px)
	),
	m: (
		fontSize: utils.ds-remCalc($label-font-size-height-m * 1px),
		lineHeight: utils.ds-remCalc($label-font-size-height-m * 1px)
	),
	l: (
		fontSize: utils.ds-remCalc($label-font-size-height-l * 1px),
		lineHeight: utils.ds-remCalc($label-font-size-height-l * 1px)
	),
	xl: (
		fontSize: utils.ds-remCalc($label-font-size-height-xl * 1px),
		lineHeight: utils.ds-remCalc($label-font-size-height-xl * 1px)
	)
);

$tokens-textTypeBody: (
	// Globals
	fontWeight: normal,
	letterSpacing: normal,
	// Using string interpolation because of the weirdness with quotes and commas
	fontFamily: $font-primary,
	// Variants
	xs:
		(
			fontSize: utils.ds-remCalc(12px),
			lineHeight: utils.ds-remCalc(20px)
		),
	s: (
		fontSize: utils.ds-remCalc(14px),
		lineHeight: utils.ds-remCalc(24px)
	),
	m: (
		fontSize: utils.ds-remCalc(16px),
		lineHeight: utils.ds-remCalc(24px)
	),
	l: (
		fontSize: utils.ds-remCalc(20px),
		lineHeight: utils.ds-remCalc(28px)
	)
);

$tokens-textTypeSubheadReg: (
	// general
	fontFamily: $font-primary,
	fontWeight: $font-primary-regular-weight,
	letterSpacing: $subhead-letter-spacing,
	// variants
	s:
		(
			fontSize: utils.ds-remCalc($subhead-font-size-s * 1px),
			lineHeight: utils.ds-remCalc($subhead-line-height-s * 1px)
		),
	m: (
		fontSize: utils.ds-remCalc($subhead-font-size-m * 1px),
		lineHeight: utils.ds-remCalc($subhead-line-height-m * 1px)
	),
	l: (
		fontSize: utils.ds-remCalc($subhead-font-size-l * 1px),
		lineHeight: utils.ds-remCalc($subhead-line-height-l * 1px)
	)
);

$tokens-textTypeSubheadBold: (
	// general
	fontFamily: $font-primary,
	fontWeight: $font-primary-bold-weight,
	letterSpacing: $subhead-letter-spacing,
	// variants
	s:
		(
			fontSize: utils.ds-remCalc($subhead-font-size-s * 1px),
			lineHeight: utils.ds-remCalc($subhead-line-height-s * 1px)
		),
	m: (
		fontSize: utils.ds-remCalc($subhead-font-size-m * 1px),
		lineHeight: utils.ds-remCalc($subhead-line-height-m * 1px)
	),
	l: (
		fontSize: utils.ds-remCalc($subhead-font-size-l * 1px),
		lineHeight: utils.ds-remCalc($subhead-line-height-l * 1px)
	)
);

$tokens-textTypeBodyReg: (
	// Globals
	fontFamily: $font-primary,
	fontWeight: $font-primary-regular-weight,
	letterSpacing: $body-font-letter-spacing,
	// Variants
	s:
		(
			fontSize: utils.ds-remCalc($body-font-size-s * 1px),
			lineHeight: utils.ds-remCalc($body-line-height-s * 1px)
		),
	m: (
		fontSize: utils.ds-remCalc($body-font-size-m * 1px),
		lineHeight: utils.ds-remCalc($body-line-height-m * 1px)
	),
	l: (
		fontSize: utils.ds-remCalc($body-font-size-l * 1px),
		lineHeight: utils.ds-remCalc($body-line-height-l * 1px)
	)
);

$tokens-textTypeBodyBold: (
	// Globals
	fontFamily: $font-primary,
	fontWeight: $font-primary-bold-weight,
	letterSpacing: $body-font-letter-spacing,
	// Variants
	s:
		(
			fontSize: utils.ds-remCalc($body-font-size-s * 1px),
			lineHeight: utils.ds-remCalc($body-line-height-s * 1px)
		),
	m: (
		fontSize: utils.ds-remCalc($body-font-size-m * 1px),
		lineHeight: utils.ds-remCalc($body-line-height-m * 1px)
	),
	l: (
		fontSize: utils.ds-remCalc($body-font-size-l * 1px),
		lineHeight: utils.ds-remCalc($body-line-height-l * 1px)
	)
);

$tokens-textTypeLegal: (
	// global
	fontFamily: $font-primary,
	fontWeight: $font-primary-regular-weight,
	letterSpacing: $legal-letter-spacing,
	// variants
	s:
		(
			fontSize: utils.ds-remCalc($legal-font-size-s * 1px),
			lineHeight: utils.ds-remCalc($legal-line-height-s * 1px)
		),
	xs: (
		fontSize: utils.ds-remCalc($legal-font-size-xs * 1px),
		lineHeight: utils.ds-remCalc($legal-line-height-xs * 1px)
	)
);

$tokens-textTypeLinksReg: (
	// Globals
	fontFamily: $font-primary,
	fontWeight: $font-primary-regular-weight,
	letterSpacing: $body-font-letter-spacing,
	// Variants
	xs:
		(
			fontSize: utils.ds-remCalc($links-font-size-xs * 1px),
			lineHeight: utils.ds-remCalc($links-line-height-xs * 1px)
		),
	s: (
		fontSize: utils.ds-remCalc($links-font-size-s * 1px),
		lineHeight: utils.ds-remCalc($links-line-height-s * 1px)
	),
	m: (
		fontSize: utils.ds-remCalc($links-font-size-m * 1px),
		lineHeight: utils.ds-remCalc($links-line-height-m * 1px)
	),
	l: (
		fontSize: utils.ds-remCalc($links-font-size-l * 1px),
		lineHeight: utils.ds-remCalc($links-line-height-l * 1px)
	)
);

$tokens-textTypeLinksBold: (
	// Globals
	fontFamily: $font-primary,
	fontWeight: $font-primary-bold-weight,
	letterSpacing: $body-font-letter-spacing,
	// Variants
	xs:
		(
			fontSize: utils.ds-remCalc($links-font-size-xs * 1px),
			lineHeight: utils.ds-remCalc($links-line-height-xs * 1px)
		),
	s: (
		fontSize: utils.ds-remCalc($links-font-size-s * 1px),
		lineHeight: utils.ds-remCalc($links-line-height-s * 1px)
	),
	m: (
		fontSize: utils.ds-remCalc($links-font-size-m * 1px),
		lineHeight: utils.ds-remCalc($links-line-height-m * 1px)
	),
	l: (
		fontSize: utils.ds-remCalc($links-font-size-l * 1px),
		lineHeight: utils.ds-remCalc($links-line-height-l * 1px)
	)
);

$tokens-textTypes: (
	body: $tokens-textTypeBodyReg,
	body-reg: $tokens-textTypeBodyReg,
	body-bold: $tokens-textTypeBodyBold,
	heading: $tokens-textTypeHeading,
	display: $tokens-textTypeDisplay,
	subhead-reg: $tokens-textTypeSubheadReg,
	subhead-bold: $tokens-textTypeSubheadBold,
	label: $tokens-textTypeLabel,
	legal: $tokens-textTypeLegal,
	links: $tokens-textTypeLinksReg,
	links-bold: $tokens-textTypeLinksBold
);
