/** @format */

$tokens-gridColumnCount: 12;

$tokens-grid: (
	column-count: $tokens-gridColumnCount,
	column-width: (
		100 / $tokens-gridColumnCount
	) * 1%,
	gutter-width: (
		xs: 24px,
		s: 24px,
		m: 24px,
		l: 24px,
		xl: 32px
	)
);

$tokens-gridContainerPage: (
	margins: (
		xs: 16px,
		s: 24px,
		m: 24px,
		l: 28px,
		xl: 40px
	)
);

$tokens-gridContainers: (
	page: $tokens-gridContainerPage
);
